import React, { useState, useEffect } from "react";
import "./Navbar.css";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";
import FirstPage from "../firstpage/FirstPage";
import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useLocalStorage } from "../../../local_storage/LocalStorage";

const API_URL = process.env.REACT_APP_API_URL;

function Navbar() {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [email, setEmail] = useState("");

  const { t, i18n } = useTranslation();
  const [click, setClick] = useState(false);
  const [click2, setClick2] = useState(false);
  const [userLanguage, setUserLanguage] = useLocalStorage("userLanguage", "en");
  const changeLng = (lang) => {
    i18n.changeLanguage(lang);
    setUserLanguage(lang);
    statusFile(lang);
  };
  const handleClick = () => setClick(!click);
  const handleClick2 = () => setClick2(!click2);

  const getUserProfile = () => {
    changeLng(userLanguage);
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + "/accounts", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              if (response.data) {
                const data = response.data.settings.language;
                console.log("User profile: ", response.data);
                console.log("Lang : ", data);
                setEmail(response.data.profiles.email);
                if(userLanguage !== data){
                  changeLng(data);
                }
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const statusFile = (x) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log(tokenResponse);
        let data = {
          replace: "/settings/language",
          value: x,
        };
        axios
          .patch(API_URL + "/accounts", data, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
        //   .then((res) => {
        //     console.log("res : ", res);
        //   });
      });
  };

  useEffect(() => {
    getUserProfile();
  }, []);

  return (
    <>
      <nav className="NavbarItem">
        {/* <h1 className="navbar-logo"><a href="/"><img src={logo} alt="" /></a></h1> */}
        <Link exact to="/" style={{textDecoration: "none"}}>
          <h1 style={{ color: "white", fontSize: "38px" }}>eSigns</h1>
        </Link>
        <div className="lang">
          <i className="fad fa-globe-africa"></i>
          {
            userLanguage === "en" ? <a onClick={() => changeLng("th")}>ไทย</a> : <a onClick={() => changeLng("en")}>English</a>
          }
        </div>

        <div className="lang-icon" onClick={handleClick2}>
          <i
            className={click2 ? "fas fa-globe-africa" : "fad fa-globe-africa"}
          ></i>
        </div>
        <ul
          className={click2 ? "lang-sm active" : "lang-sm "}
          onClick={handleClick2}
        >
          <li>
            <a onClick={() => changeLng("en")}>EN</a>
          </li>
          <li>
            <a onClick={() => changeLng("th")}>TH</a>
          </li>
        </ul>

        <div className="menu-icon" onClick={handleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"}></i>
        </div>
        <div className={click ? "nav-transparent-blur" : null}>
          <ul
            className={click ? "nav-menu active" : "nav-menu"}
            onClick={handleClick}
          >
            <FirstPage />
          </ul>
        </div>

        <div className="profile" onClick={handleClick}>
          <ul className={click ? "profile active" : "profile"}>
            <li>
              {" "}
              <a className="items">{email}</a>
              <i
                className={click ? "fas fa-chevron-up" : "fas fa-chevron-down"}
              ></i>
            </li>
            <ul className={click ? "sub-item active" : "sub-item"}>
              <li>
                <a className={click ? "sub-items active" : "sub-items"}>
                  <FirstPage />
                </a>
              </li>
            </ul>
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
