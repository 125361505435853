import axios from "axios";
import { silentRequest } from "../authConfig";

/**
 * @function DownloadPDF download PDF or XML
 * @param {Object} payload {instance, account}
 * @param {boolean} convertFile convert file to pdf blob ? default : true
 * @returns if convertFile = true : pdf blob || else : response
 */
export const DownloadPDF = (payload, convertFile = true) => {
  return new Promise((resolve, reject) => {
    payload.instance
      .acquireTokenSilent({ ...silentRequest, account: payload.account })
      .then((tokenResponse) => {
        axios
          .get("https://ws.esigns.cloud/files/" + payload.id, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
            responseType: "blob",
          })
          .then((res) => {
            if (convertFile) {
              const file = new Blob([res.data], {
                type: "application/pdf",
              });
              resolve(file);
            } else {
              resolve(res);
            }
          });
      })
      .catch((error) => {
        console.log(error);
        payload.instance.acquireTokenRedirect(silentRequest);
        reject();
      });
  });
};
