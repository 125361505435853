import axios from "axios";
import { silentRequest } from "../authConfig";

/**
 * @function GetJobProcess check job process
 * @param {Object} payload {instance, account, id}
 * @returns response
 */
export const GetJobProcess = (payload) => {
  return new Promise((resolve, reject) => {
    payload.instance
      .acquireTokenSilent({ ...silentRequest, account: payload.account })
      .then((tokenResponse) => {
        axios
          .get("https://ws.esigns.cloud/jobs/" + payload.id, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then((res) => {
            resolve(res);
          });
      })
      .catch((error) => {
        console.log(error);
        payload.instance.acquireTokenRedirect(silentRequest);
        reject();
      });
  });
};
