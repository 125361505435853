import React, { useState, useEffect } from "react";
import axios from "axios";

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import {
  mergeStyles,
  PrimaryButton,
  DefaultButton,
  IconButton,
  Stack,
  Icon,
  FontIcon,
  Spinner,
  mergeStyleSets
} from "@fluentui/react";

import moment from "moment";

import "./MobileCertificates.css";

const API_URL = process.env.REACT_APP_API_URL;

//Style

const iconClass = mergeStyles({
  fontSize: 26,
  paddingLeft: "10px",
  paddingTop: "3px",
  color: "green",
});

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const classList = mergeStyleSets({
  flexCenter : {
    alignItems: "center"
  },
  headerLabel : {
    marginLeft: "0%", 
    marginTop: "5%",
    width: "90vw",
    fontSize: "x-large"
  },
  describeLabel : {
    color: "gray", 
    width: "90vw"
  },
  addCertificateButton : {
    marginTop: "10% !important" 
  },
  selectButton : {
    paddingTop: "5%", 
    paddingBottom: "5%",
    alignItems: "flex-end"
  }
});


const MobileCertificates = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const [, setCertificates] = useState([
    {
      certificateName: "",
      createdBy: "",
      createdTime: "",
      lastAccessTime: "",
      email: "",
      id: "",
      issuer: {
        C: "",
        CN: "",
        E: "",
        O: "",
      },
      serialNumber: "",
      subject: {
        C: "",
        CN: "",
        E: "",
        L: "",
        OU: "",
        organizationIdentifier: "",
      },
      validity: {
        notBefore: "",
        notAfter: "",
      },
    },
  ]);
  const [certificateCards, setCertificateCards] = useState([]);
  const [isLoadComplete, setIsLoadComplete] = useState(false);

  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const deleteCertificate = (certificateId) => {
    // console.log("Delete certificate!");

    let confirm = window.confirm(t("Please confirm to delete certificate!"));

    if (confirm) {
      setIsLoadComplete(false);

      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          axios
            .delete(API_URL + "/certificates/" + certificateId, {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
              },
            })
            .then(
              (response) => {
                // console.log("Delete certificate response: ", response);

                getAllData();

                //setIsLoadComplete(true);
              },
              (error) => {
                console.log(error);
              }
            );
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  };

  const getCertificates = (accessToken, certificateId) => {
    //Retrieve account
    axios
      .get(API_URL + "/certificates", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(
        (response) => {
        //   console.log("Account data: ", response);

          if (response.data) {
            // console.log("Certificates: ", response.data);

            let _items = [];
            let _cards = [];

            response.data.forEach((item) => {
              _items.push({
                certificateName: item.certificateName,
                createdBy: item.createdBy,
                createdTime: item.createdTime,
                lastAccessTime: item.lastAccessTime,
                email: item.email,
                id: item.id,
                issuer: {
                  C: item.issuer.C,
                  CN: item.issuer.CN,
                  E: item.issuer.E,
                  O: item.issuer.O,
                },
                serialNumber: item.serialNumber,
                subject: {
                  C: item.subject.C,
                  CN: item.subject.CN,
                  E: item.subject.E,
                  L: item.subject.L,
                  OU: item.subject.OU,
                  organizationIdentifier: item.subject.organizationIdentifier,
                },
                validity: {
                  notBefore: item.validity.notBefore,
                  notAfter: item.validity.notAfter,
                },
              });

              _cards.push(createCards(item, certificateId));
            });

            // console.log("Certificates Items: ", _items);
            setCertificates(_items);
            setIsLoadComplete(true);

            setCertificateCards(_cards);
          }
        },
        (error) => {
          console.log(error);
          //b2cauth.run();
        }
      );
  };

  const getDefaultCertificateId = (accessToken) => {
    //Retrieve account
    axios
      .get(API_URL + "/accounts", {
        headers: {
          Authorization: "Bearer " + accessToken,
        },
      })
      .then(
        (response) => {
        //   console.log("Account data: ", response);

          if (response.data) {
            getCertificates(
              accessToken,
              response.data.settings.defaultCertificateId
            );
          }
        },
        (error) => {
          console.log(error);
        }
      );
  };

  const createCards = (item, certificateId) => {
    return (
        <div className="mobile-certificates-card">
          <Stack horizontal>
            <p className="id-default">{item.certificateName}</p>
            {certificateId === item.id && (
              <FontIcon iconName="SkypeCircleCheck" className={iconClass} />
            )}
          </Stack>

          <Stack horizontal style={{ marginTop: "15px", marginBottom: "12px" }}>
            <p>
              <i className="fas fa-address-card"></i> {t("Serial number")}:
            </p>
            <span>&nbsp;{item.serialNumber}</span>
          </Stack>

          {item.email && (
            <Stack
              horizontal
              style={{ marginTop: "5px", marginBottom: "12px" }}
            >
              <h4>
                <i className="fas fa-envelope"></i> {t("Email")}:{" "}
              </h4>
              <span>&nbsp;{item.email}</span>
            </Stack>
          )}

          <Stack horizontal style={{ marginTop: "5px", marginBottom: "12px" }}>
            <h4>
              <i className="fas fa-university"></i> {t("Issued by")}:{" "}
            </h4>
            <span>&nbsp;{item.issuer.CN}</span>
          </Stack>

          <Stack vertical >
            <Stack vertical>
              <Stack
                horizontal
                style={{ marginTop: "5px", marginBottom: "12px" }}
              >
                <h4>
                  <i className="fas fa-hourglass-start"></i> {t("Start Date")}:{" "}
                </h4>
                <span>
                  &nbsp;
                  {moment(item.validity.notBefore).format(
                    "DD/MM/YYYY  HH:mm:ss"
                  )}
                </span>
              </Stack>

              <Stack
                horizontal
              >
                <h4>
                  <i className="fas fa-hourglass-end"></i> {t("Expire Date")}:{" "}
                </h4>
                <span>
                  &nbsp;
                  {moment(item.validity.notAfter).format(
                    "DD/MM/YYYY  HH:mm:ss"
                  )}
                </span>
              </Stack>
            </Stack>

            {certificateId !== item.id && (
              <Stack
                vertical
                verticalAlign="end"
                className={classList.selectButton}
              >
                <Stack horizontal>
                  <DefaultButton
                    text={t("Set Default")}
                    onClick={() => clickSetDefault(item.id)}
                  ></DefaultButton>
                  &nbsp;
                  <IconButton
                    iconProps={{
                      iconName: "Delete",
                      styles: {
                        root: {
                          fontSize: 26,
                          color: "black",
                          marginBottom: "3px",
                        },
                      },
                    }}
                    title={t("Delete")}
                    className="certificates-delete-btn"
                    onClick={(event) => {
                      deleteCertificate(item.id);
                    }}
                  ></IconButton>
                </Stack>
              </Stack>
            )}
          </Stack>
        </div>
    );
  };

  const clickSetDefault = (certificateId) => {
    setIsLoadComplete(false);

    // console.log("Select id: ", certificateId);
    // console.log("All cards: ", certificateCards);

    //setDefaultCertificateId(certificateId);

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        // console.log(tokenResponse);

        let data = {
          replace: "/settings/defaultCertificateId",
          value: certificateId,
        };

        axios
          .patch(API_URL + "/accounts", data, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
            //   console.log("click set default: ", response);
              //setIsLoadComplete(true);

              getAllData();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const getAllData = () => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        // console.log(tokenResponse);
        getDefaultCertificateId(tokenResponse.accessToken);
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  useEffect(() => {
    // console.log("userEffect initial Call!");

    setIsLoadComplete(false);

    getAllData();
  }, [t]);

  return (
    <Stack className={classList.flexCenter}>
      <h1 className={classList.headerLabel}>
        <Icon iconName="Certificate" /> {t("My Digital Signature")}
      </h1>
      <h4 className={classList.describeLabel}>
        {t("A certificate issued by a certificate authority")}
      </h4>
      {isLoadComplete ? (
        <Stack>
          <Stack>{certificateCards}</Stack>
          <Stack horizontal horizontalAlign="center" className={classList.addCertificateButton}>
            <PrimaryButton
              iconProps={{ iconName: "Add" }}
              name="add"
              text={t("Add Certificate")}
              style={{ fontSize: "20px", height: "50px" }}
              onClick={() => {

                history.push({
                  pathname: "/certificates/add/p12",
                  state: {},
                });
              }}
            />
          </Stack>
          <br />
        </Stack>
      ): !isLoadComplete ? (
        /* { true && */
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner
                label={t("Processing...")}
                labelPosition="down"
                styles={spinnerStyles}
              />
            </div>
            <br />
          </Stack>
        </Stack>
      ) : null}

      <br />
      <br />
    </Stack>
  );
};

export default MobileCertificates;
