import "./App.css";
// import Dropzone from "./Desktop/Components/Dropbox";
import React from "react";
import Navbar from "./Desktop/Components/Navbar/Navbar";
// import Sidebar from "./Desktop/Components/slidebar/Sidebar";
import FluentSidebar from "./Desktop/Components/sidebar/FluentSidebar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";

import { Stack, initializeIcons } from "@fluentui/react";

// MSAL imports
import { MsalProvider } from "@azure/msal-react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

import { isMobile } from "react-device-detect";
import { loginRequest } from "./authConfig";
import DigitalSigns from "./Desktop/Components/digitalSign/DigitalSigns";
import DigitalSignature from "./Desktop/Components/digital-signatures/DigitalSignature";
import Certificates from "./Desktop/Components/certificates/Certificates";
import P12 from "./Desktop/Components/certificates/add/p12/P12";
import PDFDropzone from "./Desktop/Components/OpenPDF/PDFDropzone";
import MobileDocumentWindow from "./Mobile/Components/OpenPDF/DocumentWindow";
import MobileCertificates from "./Mobile/Components/certificates/MobileCertificates";
import MobileP12 from "./Mobile/Components/certificates/add/p12/MobileP12";
import ContactUs from "./contact_us/contact"

initializeIcons();

const stackStyles = {
  root: {
    height: "100vh",
    margin: 0,
    padding: 0,
    width: "100vw",
    minHeight: "100%",
  },
};

function App({ pca }) {
  const authRequest = {
    ...loginRequest,
  };

  let pathName = window.location.pathname; 
  let isPathMobile = pathName.startsWith("/m");

  return (
    <MsalProvider instance={pca}>
      <MsalAuthenticationTemplate
        interactionType={InteractionType.Redirect}
        authenticationRequest={authRequest}
      >
        <Router>
          <Stack styles={stackStyles} disableShrink={false} wrap={false} style={{ width: "100vw"}}>
            <Stack horizontal>
              <Navbar />
            </Stack>
            <Stack horizontal style={{ width: "100vw"}}>
              {(isPathMobile || isMobile) ? null : (
                <Stack
                  vertical
                  style={{
                    width: "250px",
                    height: "100%",
                    minHeight: "calc(100vh - 60px)",
                    backgroundColor: "#2E302D",
                  }}
                >
                  <FluentSidebar />
                </Stack>
              )}
              <Stack vertical style={{ width: (isPathMobile || isMobile) ? "100vw" : "calc(100vw - 267px)" }}>
                <Stack horizontal horizontalAlign="center" wrap={false}>
      
                  <Switch>
                    <Route exact path="/">
                      { isMobile ? <Redirect to="/m" /> : <PDFDropzone /> }
                    </Route>
                    <Route exact path="/m">
                      <MobileDocumentWindow />
                    </Route>

                    <Route exact path="/digital-signatures">
                      { isMobile ? <Redirect to="/m/documents" /> : <DigitalSignature />}
                    </Route>

                    <Route exact path="/certificates">
                      { isMobile ? <MobileCertificates /> : <Certificates />}
                    </Route>

                    <Route exact path="/certificates/add/p12">
                      { isMobile ? <MobileP12 /> : <P12 />}
                    </Route>
                    
                    <Route exact path="/digitalsign">
                      { isMobile ? <DigitalSigns /> : <DigitalSigns />}
                    </Route>

                    <Route exact path="/electronic-signatures">
                    { isMobile ? <Redirect to="/m/documents" /> : <PDFDropzone /> }
                    </Route>
                    <Route exact path="/m/documents">
                      <MobileDocumentWindow />
                    </Route>
                    <Route exact path="/contact-us">
                      <ContactUs />
                    </Route>

                    {isMobile ? <Redirect to="/m" /> : <Redirect to="/" />}
                  </Switch>

                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Router>
      </MsalAuthenticationTemplate>
    </MsalProvider>
  );
}

export default App;
