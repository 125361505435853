import axios from "axios";
import { silentRequest } from "../authConfig";

/**
 * @function SendFile send file to another user pending flow
 * @param {Object} payload {instance, account, name, email: user email, receiver: receiver email, message, id}
 * @returns {Promise} response
 */
export const SendFile = (payload) => {   
  return new Promise((resolve, reject) => {
    payload.instance
      .acquireTokenSilent({ ...silentRequest, account: payload.account })
      .then((tokenResponse) => {
        axios
          .post("https://ws.esigns.cloud/documents/send", {
            from: {
              name: payload.name,
              email: payload.email,
            },
            to: {
              name: null,
              email: payload.receiver,
            },
            message: payload.message,
            documentId: payload.id,
          }, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then((res) => {
            resolve(res);
          });
      })
      .catch((error) => {
        console.log(error);
        payload.instance.acquireTokenRedirect(silentRequest);
        reject();
      });
  });
};