import { useTranslation } from "react-i18next";
import { React } from "react";
import { FontIcon } from "@fluentui/react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import "../contact_us/contact.css";

const ContactUs = () => {
  const { t } = useTranslation();
  initializeIcons();

  return (
    <div className="contact-us">
      <b className="contact-header">
        <FontIcon
          aria-label="Telemarketer"
          iconName="Telemarketer"
          className="contact-icon"
        />
        {t("Customer services")}
      </b>
      <div className="qr-container">
        <img src="/images/qr.PNG" alt="qr_img" className="qr" />
      </div>

      <div className="contact-desc">
        <p><span>Line: </span><a href="https://lin.ee/bP9vxcQ">@esigns</a></p>
        <p>{t("Tel. (+66)82-579-8555")}</p>
        <div className="contact-office-hrs">
            <p>{t("Office hours 8.30AM to 5.30PM")}&nbsp;</p>
            <p>{t('(every days)')}</p>
        </div>
        
      </div>
    </div>
  );
};

export default ContactUs;
