import React, { useCallback, useState, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { useMsal, useAccount } from "@azure/msal-react";
import { Pagination } from "@uifabric/experiments/lib/Pagination";
import paginate from "jw-paginate";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PdfWindow from "./PdfWindow";
import PdfAllPages from "./PdfAllPages";
import "./css/PDFDropzone.css";

import { DownloadPDF } from "../../../api_function/downloadPDF";
import { ListFilePDF } from "../../../api_function/listFilePDF";
import { DeletePDF } from "../../../api_function/deletePDF";
import { RejectSign } from "../../../api_function/rejectSign";
import { SendWindow } from "./SendWindow";

import { useSessionStorage } from "../../../session_storage/Session";

import {
  ShimmeredDetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  DefaultButton,
  Stack,
  mergeStyles,
  mergeStyleSets,
  Spinner,
  Pivot,
  PivotItem,
  MessageBarType,
  MessageBar,
  MessageBarButton,
} from "@fluentui/react";
import { useLocalStorage } from "../../../local_storage/LocalStorage";

const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const alertStyle = {
  root: {
    position: "fixed",
    top: "60px",
    left: "250px",
    width: "calc(100vw - 250px)",
    height: "5vh",
    zIndex: 10,
  },
  iconContainer: {
    alignItems: "center",
  },
  text: {
    alignItems: "center",
  },
  innerText: {
    fontSize: "larger",
  },
};

function PDFDropzone() {
  const { t, i18n } = useTranslation();
  const [pdfSaving, setPdfSaving] = useState();
  const [pdfName, setPdfName] = useState("untitled.pdf");
  const [pageData, setPageData] = useState({});
  const pageSize = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [loadPdfComplete, setLoadPdfComplete] = useState(false);
  const [signStatus, setSignStatus] = useState("");
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [openingFile, setOpeningFile] = useState(false);
  const [sendFile, setSendFile] = useState(false);
  const [dummyForUpdateList, setDummyForUpdateList] = useState(false);

  const [pendingItems, setPendingItems] = useState([]);
  const [signedItems, setSignedItems] = useState([]);
  const [sentItems, setSentItems] = useState([]);

  const [flowSelecting, setFlowSelecting] = useSessionStorage(
    "flowSelecting",
    "pending"
  );
  const [monthSelecting, setMonthSelecting] = useLocalStorage(
    "monthSelecting",
    parseInt(moment().format("MM"))
  );
  const [yearSelecting, setYearSelecting] = useLocalStorage(
    "yearSelecting",
    parseInt(moment().format("YYYY"))
  );

  const [defaultAlert, setDefaultAlert] = useState(false);
  const [sendSuccessAlert, setSendSuccessAlert] = useState(false);
  const [sendFailAlert, setSendFailAlert] = useState(false);

  const [, setPendingEsign] = useSessionStorage("pendingEsign", 0);
  const [, setPendingDigitalsign] = useSessionStorage("pendingDigitalsign", 0);

  var payload = {
    instance: instance,
    account: account,
  };

  const onDrop = useCallback((acceptedFiles) => {
    //anti-multifile fitler
    if (acceptedFiles[1]) {
      alert(t("Please input only one file at once."));
      return;
    }
    //extension file validation
    let fileExt = acceptedFiles[0].name.split(".");
    let fileExtLenght = fileExt.length - 1;
    if (fileExt[fileExtLenght] !== "pdf") {
      alert(t("Please input .pdf file only."));
      return;
    }
    const reader = new FileReader();
    reader.onabort = () => console.log("file reading was aborted");
    reader.onerror = () => console.log("file reading has failed");
    reader.onload = () => {
      // Do whatever you want with the file contents
      const binaryStr = reader.result;
      let name = "";
      for (let i in fileExt) {
        if (i < fileExtLenght) {
          name += fileExt[i];
        }
      }
      name += "-eSigns.pdf";
      setPdfName(name);
      setPdfSaving(binaryStr);
    };
    reader.readAsArrayBuffer(acceptedFiles[0]);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  useEffect(() => {
    if (defaultAlert) {
      setInterval(() => {
        setDefaultAlert(false);
      }, 3000);
    }
  }, [defaultAlert]);

  useEffect(() => {
    if (sendSuccessAlert) {
      setInterval(() => {
        setSendSuccessAlert(false);
        setDefaultAlert(false);
      }, 3000);
    }
  }, [sendSuccessAlert]);

  useEffect(() => {
    let local_payload = payload;
    if (
      document.getElementById("month-filter") &&
      document.getElementById("year-filter")
    ) {
      document.getElementById("month-filter").onchange = () => {
        setDocuments([]);
        setMonthSelecting(
          parseInt(document.getElementById("month-filter").value)
        );
      };
      document.getElementById("year-filter").onchange = () => {
        setDocuments([]);
        setYearSelecting(
          parseInt(document.getElementById("year-filter").value)
        );
      };
      local_payload.month = parseInt(monthSelecting);
      local_payload.year = parseInt(yearSelecting);
    }
    if (local_payload.month && local_payload.year) {
      ListFilePDF(local_payload).then(
        (response) => {
          let alldocuments = [];
          let pend = { e: 0, d: 0 };
          if (response.data.length > 0) {
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].type === "electronic") {
                alldocuments.push({
                  key: response.data[i].id,
                  fileDisplayName: response.data[i].file.displayName,
                  fileExtension: response.data[i].file.extension,
                  fileLastModified: response.data[i].file.lastModified,
                  fileSize: response.data[i].file.size,
                  type: response.data[i].type,
                  flow: response.data[i].flow,
                  status: response.data[i].status,
                });
                if (response.data[i].flow === "pending") {
                  pend.e += 1;
                }
              } else if (
                response.data[i].type === "digital" &&
                response.data[i].flow === "pending"
              ) {
                pend.d += 1;
              }
            }
            
            setPendingEsign(pend.e);
            setPendingDigitalsign(pend.d);
          }
          if(pend.e === 0) {
            setFlowSelecting("signed");  
          }
          setDocuments(alldocuments);
          setLoadDataComplete(true);
        },
        (error) => {
          console.log(error);
        }
      );
    }
  }, [
    loadDataComplete,
    dummyForUpdateList,
    monthSelecting,
    yearSelecting,
    pdfSaving,
    sendFile,
  ]);

  useEffect(() => {
    setCurrentPage(1);
    if (
      flowSelecting !== JSON.parse(sessionStorage.getItem("flowSelecting")) &&
      sessionStorage.getItem("flowSelecting")
    ) {
      setFlowSelecting(JSON.parse(sessionStorage.getItem("flowSelecting")));
    }
    if (
      monthSelecting !== JSON.parse(sessionStorage.getItem("monthSelecting")) &&
      sessionStorage.getItem("monthSelecting")
    ) {
      setMonthSelecting(JSON.parse(sessionStorage.getItem("monthSelecting")));
    }
    if (
      yearSelecting !== JSON.parse(sessionStorage.getItem("yearSelecting")) &&
      sessionStorage.getItem("yearSelecting")
    ) {
      setYearSelecting(JSON.parse(sessionStorage.getItem("yearSelecting")));
    }
  }, [loadDataComplete, pdfSaving, sendFile]);

  useEffect(() => {
    if (documents) {
      setPendingItems(getFlow("pending"));
      setSignedItems(getFlow("signed"));
      setSentItems(getFlow("sent"));
    }
  }, [documents]);

  useEffect(() => {
    function getFlowItems() {
      if (flowSelecting === "pending") {
        return pendingItems;
      } else if (flowSelecting === "signed") {
        return signedItems;
      } else if (flowSelecting === "sent") {
        return sentItems;
      } else return pendingItems;
    }
    let flowItems = getFlowItems();
    if (flowItems.length > 0) {
      const _docs = [];
      for (
        let i = (currentPage - 1) * pageSize;
        i < Math.min(currentPage * pageSize, flowItems.length);
        i++
      ) {
        _docs.push({
          key: flowItems[i].key,
          fileDisplayName: flowItems[i].fileDisplayName,
          fileExtension: flowItems[i].fileExtension,
          fileLastModified: flowItems[i].fileLastModified,
          fileSize: flowItems[i].fileSize,
          type: flowItems[i].type,
          flow: flowItems[i].flow,
          status: flowItems[i].status,
        });
      }
      setItems(_docs);
    } else setItems([]);
  }, [currentPage, flowSelecting, pendingItems, signedItems, sentItems]);

  const iconClass = mergeStyles({
    fontSize: 120,
    height: 120,
    width: 120,
    margin: "0 60px",
  });

  const classNames = mergeStyleSets({
    fileIconHeaderIcon: {
      padding: 0,
      fontSize: "16px",
    },
    fileIconCell: {
      textAlign: "center",
      selectors: {
        "&:before": {
          content: ".",
          display: "inline-block",
          verticalAlign: "middle",
          height: "100%",
          width: "0px",
          visibility: "hidden",
        },
      },
    },
    fileIconImg: {
      verticalAlign: "middle",
      maxHeight: "16px",
      maxWidth: "16px",
    },
    controlWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    exampleToggle: {
      display: "inline-block",
      marginBottom: "10px",
      marginRight: "30px",
    },
    selectionDetails: {
      marginBottom: "20px",
    },
    blue: [
      {
        color: "#0078D4",
      },
      iconClass,
    ],
    filter: {
      minWidth: "320px",
      height: "44px",
      position: "relative",
      top: "0px",
      left: "-320px",
      textAlign: "baseline",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "'CSChatThaiUI', sans-serif !important",
      fontSize: "medium",
      color: "rgb(50, 49, 48)",
    },
  });

  const onPageChange = (selectedPageIndex) => {
    setCurrentPage(selectedPageIndex + 1);
  };

  const cancelSign = () => {
    setUrl(null);
    setLoadPdfComplete(false);

    setSignStatus("");
    setLoadDataComplete(false);
    setOpeningFile(false);
  };

  const downloadSign = () => {
    if (openingFile) {
      download(openingFile);
    }
  };

  const download = (item) => {
    let local_payload = payload;
    local_payload.id = item.key;
    DownloadPDF(local_payload).then(
      (response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", item.fileDisplayName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const openFile = (item) => {
    setIsLoading(true);
    let local_payload = payload;
    local_payload.id = item.key;
    DownloadPDF(local_payload).then(
      (response) => {
        setUrl(window.URL.createObjectURL(response));
        setLoadPdfComplete(true);
        setSignStatus("complete");
        setIsLoading(false);
      },
      (error) => {
        console.log(error);
      }
    );
  };

  /** @param {object} item {item.key: doc id} */
  const rejectFile = (item) => {
    let local_payload = payload;
    local_payload.id = item.key;
    let confirmed = window.confirm(t("Are you confirm to reject this file?"));
    if (confirmed) {
      setDefaultAlert(t("Reject file..."));
      RejectSign(local_payload).then((res) => {
        if (res === 200) {
          setSendSuccessAlert(t("Successful reject"));
        } else {
          setSendFailAlert(t("Fail to reject"));
        }
      });
    }
  };

  const deleteFile = (item) => {
    let local_payload = payload;
    local_payload.id = item.key;
    let confirmed = window.confirm(t("Are you confirm to delete this file?"));
    if (confirmed) {
      setDefaultAlert(t("Deleting file..."));
      DeletePDF(local_payload).then((res) => {
        if (res === 200) {
          setSendSuccessAlert(t("Successful delete"));
        } else {
          setSendFailAlert(t("Fail to delete"));
        }
      });
    }
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const columns = [
    {
      key: "column1",
      name: t("Name"),
      fieldName: "fileDisplayName",
      minWidth: 400,
      maxWidth: 400,
      isRowHeader: true,
      // isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onRender: (item) => {
        return (
          <span
            className="pointer-cursor columns-list-span"
            onClick={() => {
              openFile(item);
            }}
          >
            {item.fileDisplayName}
          </span>
        );
      },
      data: "string",
      isPadded: true,
    },
    {
      key: "column2",
      name: t("Signed Date"),
      fieldName: "fileLastModified",
      minWidth: 200,
      maxWidth: 200,
      // isResizable: true,
      isCollapsible: false,
      data: "number",
      onRender: (item) => {
        let fileModifiedTime = moment(item.fileLastModified);
        return (
          <span className="columns-list-span">
            {fileModifiedTime.format("DD/MM/YYYY  HH:mm:ss")}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: "column3",
      name: t("File Size"),
      fieldName: "fileSize",
      minWidth: 100,
      maxWidth: 100,
      // isResizable: true,
      isCollapsible: false,
      data: "number",
      onRender: (item) => {
        return (
          <span className="columns-list-span">
            {formatBytes(item.fileSize)}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: "context",
      fieldName: "Context",
      minWidth: 100,
      maxWidth: 100,
      // isResizable: true,
      isCollapsible: false,
      data: "number",
      onRender: (item) => {
        return (
          <span className="columns-list-span">
            <DefaultButton
              text={t("Select")}
              menuProps={{
                items: [
                  {
                    key: "Download",
                    text: t("Download"),
                    iconProps: { iconName: "DownloadDocument" },
                    onClick: () => {
                      download(item);
                    },
                  },
                  {
                    key: "Open",
                    text: t("Open File"),
                    iconProps: { iconName: "OpenFile" },
                    onClick: () => {
                      setOpeningFile(item);
                      openFile(item);
                    },
                  },
                ]
                  .concat(
                    flowSelecting === "pending"
                      ? [
                          {
                            key: "Reject",
                            text: t("Reject"),
                            iconProps: { iconName: "Cancel" },
                            onClick: () => {
                              rejectFile(item);
                              setDummyForUpdateList(!dummyForUpdateList);
                            },
                          },
                        ]
                      : []
                  )
                  .concat([
                    {
                      key: "Delete",
                      text: t("Delete File"),
                      iconProps: { iconName: "Delete" },
                      onClick: () => {
                        deleteFile(item);
                        setDummyForUpdateList(!dummyForUpdateList);
                      },
                    },
                    {
                      key: "Send",
                      text: t("Send File"),
                      iconProps: { iconName: "Send" },
                      onClick: () => {
                        setSendFile(item);
                      },
                    },
                  ]),
              }}
              styles={{
                root: {
                  fontSize: "16px",
                  padding: "5%",
                },
                menuIcon: { fontSize: "15px" },
              }}
            />
          </span>
        );
      },
      isPadded: true,
    },
  ];

  const getFlow = (flow) => {
    let dataFlow = [];
    documents.map((item) => {
      if (item.flow === flow) {
        dataFlow.push(item);
      }
    });
    return dataFlow;
  };

  const GetShowingItems = () => {
    return (
      <div>
        <ShimmeredDetailsList
          items={items || []}
          enableShimmer={!loadDataComplete}
          compact={false}
          columns={columns}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
        {!loadPdfComplete && !isLoading && (
          <Stack horizontalAlign="center">
            <br />
            {!(items.length === 0 || items.length < pageSize) ||
            currentPage !== 1 ? (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages} //total pages
                onPageChange={onPageChange} //event when change page
                format
                firstPageIconProps={{ iconName: "DoubleChevronLeft" }}
                previousPageIconProps={{ iconName: "ChevronLeft" }}
                nextPageIconProps={{ iconName: "ChevronRight" }}
                lastPageIconProps={{ iconName: "DoubleChevronRight" }}
              />
            ) : null}
            <br />
            <br />
          </Stack>
        )}
      </div>
    );
  };

  useEffect(() => {
    if (!pdfSaving) {
      document.getElementById("lesspad").classList.remove("pdfscreen");
      document.getElementById("lesspad").classList.remove("pdfscreenfull");
    }
  }, [pdfSaving]);

  return (
    <div className="containers" id="lesspad">
      {sendFailAlert ? (
        <MessageBar
          actions={
            <div>
              <MessageBarButton
                onClick={() => {
                  setSendFailAlert(false);
                }}
              >
                {t("Dismiss")}
              </MessageBarButton>
            </div>
          }
          messageBarType={MessageBarType.error}
          isMultiline={false}
          styles={alertStyle}
        >
          {sendFailAlert}
        </MessageBar>
      ) : sendSuccessAlert ? (
        <MessageBar
          actions={
            <div>
              <MessageBarButton
                onClick={() => {
                  setSendSuccessAlert(false);
                }}
              >
                {t("Dismiss")}
              </MessageBarButton>
            </div>
          }
          messageBarType={MessageBarType.success}
          isMultiline={false}
          styles={alertStyle}
        >
          {sendSuccessAlert}
        </MessageBar>
      ) : defaultAlert ? (
        <MessageBar isMultiline={false} styles={alertStyle}>
          {defaultAlert}
        </MessageBar>
      ) : null}
      {pdfSaving ? (
        <PdfWindow
          file={pdfSaving}
          setPdfSaving={setPdfSaving}
          pdfName={pdfName}
          openFile={openFile}
          setOpeningFile={setOpeningFile}
        />
      ) : sendFile ? (
        <Stack>
          <SendWindow
            file={sendFile}
            setSendFile={setSendFile}
            setSendSuccessAlert={setSendSuccessAlert}
            setSendFailAlert={setSendFailAlert}
          />
        </Stack>
      ) : loadPdfComplete && signStatus === "complete" ? (
        <Stack
          style={{ justifyContent: "flex-start", width: "calc(100vw - 267px)" }}
        >
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 50 }}
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#4D4D4D",
              width: "calc(100vw - 267px)",
            }}
          >
            <DefaultButton
              text={t("Download")}
              onClick={downloadSign}
              style={{ fontSize: "20px", height: "50px", width: "120px" }}
            ></DefaultButton>
            <DefaultButton
              text={t("Back")}
              onClick={cancelSign}
              style={{ fontSize: "20px", height: "50px", width: "120px" }}
            ></DefaultButton>
          </Stack>

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: "calc(100vw - 267px)",
                height: "100%",
              }}
            >
              <div className="all-page-container">
                <PdfAllPages pdf={url} />
              </div>
            </Stack>
          </Stack>
        </Stack>
      ) : isLoading ? (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="dropzone-loading-center">
              <Spinner
                label={t("Exporting...")}
                labelPosition="down"
                styles={spinnerStyles}
              />
            </div>
            <br />
          </Stack>
        </Stack>
      ) : (
        <div className="padtop">
          <div className="head">
            <h1>
              <i class="fas fa-file-signature"></i>
              {t("Sign PDF Document")}
            </h1>
            <h4>{t("sign your document with Electronic signature")}</h4>
          </div>
          <br></br>
          <div className="Drop">
            <div className="dropzone" {...getRootProps()}>
              <input
                type="file"
                accept="application/pdf,application/json"
                {...getInputProps()}
              />
              <div className="img" style={{ marginBottom: "10px" }}>
                <i
                  className="fas fa-files-medical"
                  style={{ fontSize: "80px", color: "rgba(45, 45, 83, 0.808)" }}
                ></i>
              </div>
              {isDragActive ? (
                <p>{t("Drop the file here ...")}</p>
              ) : (
                <p>{t("Drag or Click here to sign PDF file")}</p>
              )}
              <button className="up-btn">{t("Upload File")}</button>
            </div>
          </div>
          <Stack horizontal horizontalAlign="center">
            <Stack
              horizontal
              style={{
                width: "98%",
                maxWidth: "1000px",
              }}
            >
              <Pivot
                overflowBehavior={"menu"}
                styles={{ root: { width: "1000px" } }}
                selectedKey={flowSelecting}
                onLinkClick={(item) => {
                  let flow = item.props.itemKey;
                  if (flow === "pending") {
                    setPageData(
                      paginate(pendingItems.length, currentPage, pageSize, 10)
                    );
                  } else if (flow === "signed") {
                    setPageData(
                      paginate(signedItems.length, currentPage, pageSize, 10)
                    );
                  } else if (flow === "sent") {
                    setPageData(
                      paginate(sentItems.length, currentPage, pageSize, 10)
                    );
                  } else {
                    setPageData(false);
                  }
                  setFlowSelecting(flow);
                  setCurrentPage(1);
                }}
              >
                <PivotItem
                  headerText={t("Pending")}
                  itemKey="pending"
                  itemCount={pendingItems.length}
                  itemIcon="FieldRequired"
                >
                  <GetShowingItems />
                </PivotItem>

                <PivotItem
                  headerText={t("Signed")}
                  itemIcon="PenWorkspace"
                  itemKey="signed"
                >
                  <GetShowingItems />
                </PivotItem>
                <PivotItem
                  headerText={t("Sent")}
                  itemIcon="CheckMark"
                  itemKey="sent"
                >
                  <GetShowingItems />
                </PivotItem>
              </Pivot>
              <Stack horizontal className={classNames.filter}>
                <div>
                  <p className="dropzone-filter-header">{t("Filter")}</p>
                </div>
                <div>
                  <label
                    htmlFor="month-filter"
                    className="dropzone-filter-text"
                  >
                    {t("Month")}
                  </label>
                  <select
                    id="month-filter"
                    name="month-filter"
                    className="dropzone-filter-dropdown"
                    defaultValue={monthSelecting}
                  >
                    {Array.from({ length: 12 }, (_, i) => i + 1).map(
                      (number) => {
                        /**find current month and selected */
                        return (
                          <option value={number} key={number.toString()}>
                            {i18n.language === "th"
                              ? new Date(number.toString()).toLocaleString(
                                  "th-TH",
                                  { month: "short" }
                                )
                              : new Date(number.toString()).toLocaleString(
                                  "en-EN",
                                  { month: "short" }
                                )}
                          </option>
                        );
                      }
                    )}
                  </select>
                  <label htmlFor="year-filter" className="dropzone-filter-text">
                    {t("Year")}
                  </label>
                  <select
                    id="year-filter"
                    name="year-filter"
                    className="dropzone-filter-dropdown"
                    defaultValue={yearSelecting}
                  >
                    {Array.from(
                      {
                        length:
                          (parseInt(moment().format("YYYY")) /**stop */ -
                            2021) /**start */ /
                            1 /**step */ +
                          1,
                      },
                      (_, i) => 2021 /**start */ + i * 1 /**step */
                    ).map((number) => {
                      let trans = i18n.language === "th" ? 543 : 0;
                      return (
                        <option value={number} key={number.toString()}>
                          {number + trans}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Stack>
            </Stack>
          </Stack>
        </div>
      )}
    </div>
  );
}
export default PDFDropzone;
