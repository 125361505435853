import React, { useState, useEffect } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PdfWindow from "./PdfWindow";
import PdfAllPages from "./PdfAllPages";
import XmlAllViewer from "./XmlAllViewer";
import "./css/MobileDocumentWindow.css";

import { initializeIcons } from "@fluentui/font-icons-mdl2";
import {
  DefaultButton,
  Stack,
  mergeStyles,
  mergeStyleSets,
  Spinner,
  FontIcon,
  FocusZone,
  List,
  FocusZoneDirection,
  Icon,
  getTheme,
  getFocusStyle,
  PrimaryButton,
  Toggle,
  MessageBarType,
  MessageBar,
  MessageBarButton,
} from "@fluentui/react";

import { DownloadPDF } from "../../../api_function/downloadPDF";
import { ListFilePDF } from "../../../api_function/listFilePDF";
import { DeletePDF } from "../../../api_function/deletePDF";
import { SendWindow } from "./SendWindow";
import { DigitalSignPDFA } from "../../../api_function/digitalSignPDFA";
import { DigitalSignPDF } from "../../../api_function/digitalSignPDF";
import { DigitalSignXML } from "../../../api_function/digitalSignXML";
import { GetJobProcess } from "../../../api_function/getJobProcess";
import { RejectSign } from "../../../api_function/rejectSign";
import { GetDocument } from "../../../api_function/getDocument";
import { useSessionStorage } from "../../../session_storage/Session";
import { useLocalStorage } from "../../../local_storage/LocalStorage";

initializeIcons();
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const checkIconClass = mergeStyles({
  fontSize: 18,
  marginTop: "2px",
  color: "green",
});

const alertStyle = {
  root: {
    position: "fixed",
    top: "60px",
    left: "0px",
    width: "100vw",
    height: "5vh",
    zIndex: 10,
  },
  iconContainer: {
    alignItems: "center",
  },
  text: {
    alignItems: "center",
  },
  innerText: {
    fontSize: "larger",
  },
};

function MobileDocumentWindow() {
  const { t } = useTranslation();
  const { i18n } = useTranslation("home");
  const [pdfSaving, setPdfSaving] = useState();
  const [pdfId, setPdfId] = useState("");
  const [pdfName, setPdfName] = useState("untitled.pdf");
  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [url, setUrl] = useState("");
  const [xml, setXml] = useState("");
  const [togglePdfa, setTogglePdfa] = useState(false);
  const [loadPdfComplete, setLoadPdfComplete] = useState(false);
  const [loadXmlComplete, setLoadXmlComplete] = useState(false);
  const [clickSign, setClickSign] = useState(false);
  const [signStatus, setSignStatus] = useState("");
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [selectedFile, setSelectedFile] = useState(null);
  const [openingFile, setOpeningFile] = useState(false);
  const [uploadingFile, setUploadingFile] = useState(false);
  const [sendFile, setSendFile] = useState(false);
  const [certificateName, setCertificateName] = useState("");
  const [email, setEmail] = useState("");
  const [dummyForUpdateList, setDummyForUpdateList] = useState(false);

  const [defaultAlert, setDefaultAlert] = useState(false);
  const [sendSuccessAlert, setSendSuccessAlert] = useState(false);
  const [sendFailAlert, setSendFailAlert] = useState(false);

  const [noData, setNoData] = useState(false);
  const [loadingDocument, setLoadingDocument] = useState(false);
  const [pendingItems, setPendingItems] = useState([]);
  const [signedItems, setSignedItems] = useState([]);
  const [sentItems, setSentItems] = useState([]);
  const [flowSelecting, setFlowSelecting] = useSessionStorage(
    "flowSelecting",
    "pending"
  );
  const [monthSelecting, setMonthSelecting] = useLocalStorage(
    "monthSelecting",
    parseInt(moment().format("MM"))
  );
  const [yearSelecting, setYearSelecting] = useLocalStorage(
    "yearSelecting",
    parseInt(moment().format("YYYY"))
  );
  const [detailsDrawer, setDetailsDrawer] = useState(false);
  const [openingSign, setOpeningSign] = useState(false);

  var payload = {
    instance: instance,
    account: account,
  };

  useEffect(() => {
    let local_payload = payload;
    if (
      document.getElementById("month-filter") &&
      document.getElementById("year-filter")
    ) {
      document.getElementById("month-filter").onchange = () => {
        setDocuments([]);
        setLoadingDocument(true);
        setMonthSelecting(
          parseInt(document.getElementById("month-filter").value)
        );
      };
      document.getElementById("year-filter").onchange = () => {
        setDocuments([]);
        setLoadingDocument(true);
        setYearSelecting(
          parseInt(document.getElementById("year-filter").value)
        );
      };
      local_payload.month = parseInt(monthSelecting);
      local_payload.year = parseInt(yearSelecting);
    }
    ListFilePDF(local_payload).then(
      (response) => {
        let alldocuments = [];
        let pending = 0;
        //item library here
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            alldocuments.push({
              key: response.data[i].id,
              fileDisplayName: response.data[i].file.displayName,
              fileExtension: response.data[i].file.extension,
              fileLastModified: response.data[i].file.lastModified,
              fileSize: response.data[i].file.size,
              type: response.data[i].type,
              flow: response.data[i].flow,
              status: response.data[i].status,
            });
            if (response.data[i].flow === "pending") {
              pending += 1;
            }
          }
        }
        if(pending === 0) {
          setFlowSelecting("signed");  
        }
        setDocuments(alldocuments);
        setLoadingDocument(false);
        setLoadDataComplete(true);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [loadDataComplete, dummyForUpdateList, monthSelecting, yearSelecting]);

  useEffect(() => {
    if (defaultAlert) {
      setInterval(() => {
        setDefaultAlert(false);
      }, 1500);
    }
  }, [defaultAlert]);

  useEffect(() => {
    if (sendSuccessAlert) {
      setInterval(() => {
        setSendSuccessAlert(false);
        setDefaultAlert(false);
      }, 3000);
    }
  }, [sendSuccessAlert]);

  useEffect(() => {
    if (sendFailAlert) {
      setDefaultAlert(false);
      setSendSuccessAlert(false);
      setInterval(() => {
        setSendFailAlert(false);
      }, 1500);
    }
  }, [sendFailAlert]);

  useEffect(() => {
    if (documents) {
      setPendingItems(getFlow("pending"));
      setSignedItems(getFlow("signed"));
      setSentItems(getFlow("sent"));
    }
  }, [documents]);

  useEffect(() => {
    function getFlowItems() {
      if (flowSelecting === "pending") {
        return pendingItems;
      } else if (flowSelecting === "signed") {
        return signedItems;
      } else if (flowSelecting === "sent") {
        return sentItems;
      } else return pendingItems;
    }
    setNoData(false);
    let flowItems = getFlowItems();
    if (flowItems.length > 0) {
      const _docs = [];
      for (let i = 0; i < flowItems.length; i++) {
        _docs.push({
          key: flowItems[i].key,
          fileDisplayName: flowItems[i].fileDisplayName,
          fileExtension: flowItems[i].fileExtension,
          fileLastModified: flowItems[i].fileLastModified,
          fileSize: flowItems[i].fileSize,
          type: flowItems[i].type,
          flow: flowItems[i].flow,
          status: flowItems[i].status,
        });
      }
      setItems(_docs);
    } else {
      setNoData(true);
      setItems([]);
    };
  }, [flowSelecting, pendingItems, signedItems, sentItems]);

  useEffect(() => {}, [detailsDrawer]);
  useEffect(() => {}, [togglePdfa]);

  const iconClass = mergeStyles({
    fontSize: 50,
    height: 50,
    width: 50,
  });

  const theme = getTheme();
  const { palette, semanticColors, fonts } = theme;

  const classNames = mergeStyleSets({
    list: {
      maxHeight: "calc(78vh - 60px)",
      overflowX: "hidden",
      overFlowY: "auto",
    },
    filter: {
      height: "7vh",
      width: "100vw",
      borderBottom: "1px solid rgb(237, 235, 233)",
    },
    itemCell: [
      getFocusStyle(theme, { inset: -1 }),
      {
        minHeight: 54,
        padding: 10,
        boxSizing: "border-box",
        borderBottom: `1px solid ${semanticColors.bodyDivider}`,
        display: "flex",
        selectors: {
          "&:hover": { background: palette.neutralLight },
        },
        userSelect: "none",
        overflow: "hidden",
      },
    ],
    fileIconHeaderIcon: {
      padding: 0,
      fontSize: "50px",
    },
    fileIconCell: {
      textAlign: "center",
      selectors: {
        "&:before": {
          content: ".",
          display: "inline-block",
          verticalAlign: "middle",
          height: "100%",
          width: "0px",
          visibility: "hidden",
        },
      },
    },
    fileIconImg: {
      verticalAlign: "middle",
      maxHeight: "50px",
      maxWidth: "50px",
    },
    controlWrapper: {
      display: "flex",
      flexWrap: "wrap",
    },
    exampleToggle: {
      display: "inline-block",
      marginBottom: "10px",
      marginRight: "30px",
    },
    selectionDetails: {
      marginBottom: "20px",
    },
    blue: [
      {
        color: "#0078D4",
      },
      iconClass,
    ],
    details: {
      alignSelf: "center",
      marginLeft: 10,
      color: "black",
      fontSize: "xx-large",
      flexShrink: 0,
    },

    itemContent: {
      marginLeft: 10,
      overflow: "hidden",
      flexGrow: 1,
      width: "50vw",
    },
    itemName: [
      fonts.xLarge,
      {
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
        maxWidth: "80%",
      },
    ],
    fileIcon: {
      alignSelf: "center",
      marginLeft: 10,
      color: palette.neutralTertiary,
      fontSize: "xx-large",
      flexShrink: 0,
    },
    popupbutton: {
      width: "90%",
      height: "100%",
      borderRadius: "10px",
      margin: "0 5%",
      padding: 0,
      fontSize: "large",
      border: "none",
      boxShadow: "0vw 0vw 2vw 0.5vw #888888 inset",
    },
    popupclose: {
      fontSize: "medium",
    },
  });

  useEffect(() => {
    const fileUploader = document.getElementById("uploadfile");
    if (fileUploader) {
      fileUploader.onclick = () => {};
      fileUploader.onchange = () => uploadFile();
    }

    const uploadFile = () => {
      if (fileUploader.files[1]) {
        alert(t("Please input only one file at once."));
        return;
      }
      if (fileUploader.files[0]) {
        let file = fileUploader.files[0]; //flag
        let fileExt = file.name.split(".");
        let fileExtLenght = fileExt.length - 1;
        if (
          fileExt[fileExtLenght] !== "pdf" &&
          fileExt[fileExtLenght] !== "xml"
        ) {
          alert(t("Please input .pdf or .xml file only."));
          return;
        }
        setUploadingFile(file);
        fileUploader.value = "";
      }
    };
  }, [pdfSaving, loadPdfComplete, loadXmlComplete]);

  useEffect(() => {
    if (
      flowSelecting !== JSON.parse(sessionStorage.getItem("flowSelecting")) &&
      sessionStorage.getItem("flowSelecting")
    ) {
      setFlowSelecting(JSON.parse(sessionStorage.getItem("flowSelecting")));
    }
    if (
      monthSelecting !== JSON.parse(sessionStorage.getItem("monthSelecting")) &&
      sessionStorage.getItem("monthSelecting")
    ) {
      setMonthSelecting(JSON.parse(sessionStorage.getItem("monthSelecting")));
    }
    if (
      yearSelecting !== JSON.parse(sessionStorage.getItem("yearSelecting")) &&
      sessionStorage.getItem("yearSelecting")
    ) {
      setYearSelecting(JSON.parse(sessionStorage.getItem("yearSelecting")));
    }
  }, [pdfSaving, clickSign, sendFile]);

  /** @function sign sign a document, control by loadPdfComplete and loadXmlComplete */
  const sign = () => {
    let local_payload = payload;
    local_payload.file = selectedFile;

    setClickSign(true);
    setMonthSelecting(moment().format("MM"));
    setYearSelecting(moment().format("YYYY"));
    setFlowSelecting("signed");

    if (loadPdfComplete) {
      if (togglePdfa) {
        DigitalSignPDFA(local_payload).then(async function (response) {
          setSignStatus(response.data.status);
          checkProcess(response.data.id);
        });
      } else {
        DigitalSignPDF(local_payload).then(async function (response) {
          setSignStatus(response.data.status);
          checkProcess(response.data.id);
        });
      }
    } else if (loadXmlComplete) {
      DigitalSignXML(local_payload).then(async function (response) {
        setSignStatus(response.data.status);
        checkProcess(response.data.id);
      });
    }
  };

  const cancelSign = () => {
    setUrl(null);
    setLoadPdfComplete(false);
    setXml(null);
    setLoadXmlComplete(false);

    setSignStatus("");
    setLoadDataComplete(false);
    setOpeningFile(false);

    setCertificateName("");
    setEmail("");
  };

  const downloadSign = () => {
    if (openingFile) {
      download(openingFile);
    }
  };

  const checkProcess = (id) => {
    let local_payload = payload;
    local_payload.id = id;
    var interval = setInterval(function () {
      GetJobProcess(local_payload).then(
        (res) => {
          if (res.data.status === "complete") {
            console.log("Check status: ", res.data.status);
            setOpeningFile({
              key: res.data.fileId,
              fileDisplayName: selectedFile.name,
            });
            getDocument(res.data.fileId);
            getFile(res.data.fileId);

            clearInterval(interval);
          } else if (res.data.status === "fail") {
            console.log("Check status: ", res.data.status);
            setClickSign(false);
            setSignStatus(res.data.status);
            clearInterval(interval);
          } else {
            console.log("Check status: ", res.data.status);
          }
        },
        (error) => {
          console.log(error);
        }
      );
    }, 2000);
  };

  const getFile = (fileId) => {
    // let local_payload = payload;
    payload.id = fileId;
    DownloadPDF(payload, false).then(
      (response) => {
        if (loadPdfComplete) {
          setUrl(window.URL.createObjectURL(new Blob([response.data])));

          setLoadPdfComplete(true);

          setClickSign(false);
          setSignStatus("complete");
        } else if (loadXmlComplete) {
          let reader = new FileReader();

          reader.onload = () => {
            setXml(reader.result);

            setLoadXmlComplete(true);
            //setExtension('xml');

            setClickSign(false);
            setSignStatus("complete");
          };

          reader.readAsText(new Blob([response.data]));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getDocument = (documentId) => {
    let local_payload = payload;
    local_payload.id = documentId;
    GetDocument(local_payload).then(
      (response) => {
        if (response.data.signatures) {
          setCertificateName(response.data.signatures[0].certificateName);
          setEmail(response.data.signatures[0].email);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  /** @param {object} item {item.key: doc id}
   *  @function download document (included xml) via downloadPDF.js API
   */
  const download = (item) => {
    setDefaultAlert(t("Downloading..."));
    let local_payload = payload;
    local_payload.id = item.key;
    DownloadPDF(local_payload).then(
      (response) => {
        const url = window.URL.createObjectURL(response);
        const link = document.createElement("a");
        link.href = url;

        link.setAttribute("download", item.fileDisplayName);
        document.body.appendChild(link);
        link.click();
        link.remove();
      },
      (error) => {
        console.log(error);
      }
    );
  };

  /** @param {object} item {item.key: doc id, item.fileExtension: doc extension} */
  const openFile = (item = openingFile) => {
    setOpeningFile(item);
    setDetailsDrawer(false);
    setIsLoading(true);
    let local_payload = payload;
    local_payload.id = item.key;
    DownloadPDF(local_payload, false).then(
      (response) => {
        getDocument(item.key);
        if (item.fileExtension === "pdf") {
          setUrl(window.URL.createObjectURL(new Blob([response.data])));

          setLoadPdfComplete(true);

          setSignStatus("complete");
          setIsLoading(false);
        } else if (item.fileExtension === "xml") {
          let reader = new FileReader();

          reader.onload = () => {
            console.log("xml: ", reader.result);
            setXml(reader.result);
            setLoadXmlComplete(true);
            setSignStatus("complete");
            setIsLoading(false);
          };
          reader.readAsText(new Blob([response.data]));
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  /**
   * @param {object} item {item.key: doc id, item.fileDisplayName: doc name}
   * @param {string} type option ? (default = "e") "e" : electronicsign, "d" : digitalsign
   * @function openFileWithSign : download document and convert blob to array buffer to open in PdfWindow if it was .pdf
   */
  const openFileWithSign = (item = openingFile, type = "e") => {
    setDetailsDrawer(false);
    setIsLoading(true);
    let local_payload = payload;
    local_payload.id = item.key;
    let name = "";
    let fileExt = item.fileDisplayName.split(".");
    let fileExtLenght = fileExt.length - 1;
    for (let i in fileExt) {
      if (i < fileExtLenght) {
        name += fileExt[i];
      }
    }
    if (type === "e") {
      if (fileExt[fileExtLenght] === "pdf") {
        DownloadPDF(local_payload).then(
          async (response) => {
            let buffer = await response.arrayBuffer();
            name += "-eSigns.pdf";
            setPdfName(name);
            setPdfSaving(buffer);
            setPdfId(item.key);
            setIsLoading(false);
            setSignStatus("");
          },
          (error) => {
            setIsLoading(false);
            console.log(error);
          }
        );
      } else {
        alert(t("Please input .pdf file only."));
      }
    } else if (type === "d") {
      if (fileExt[fileExtLenght] === "pdf") {
        DownloadPDF(local_payload, false).then(
          async (response) => {
            let blob = new Blob([response.data], {
              type: "application/pdf",
            });
            let file = new File([blob], name, { type: blob.type });
            setSelectedFile(file);
            name += "-eSigns.pdf";
            setPdfName(name);
            setUrl(URL.createObjectURL(response.data));
            setLoadPdfComplete(true);
            setIsLoading(false);
            setSignStatus("");
          },
          (error) => {
            setIsLoading(false);
            console.log(error);
          }
        );
      } else if (fileExt[fileExtLenght] === "xml") {
        DownloadPDF(local_payload, false).then(
          async (response) => {
            let reader = new FileReader();
            reader.onload = async () => {
              let blob = new Blob([response.data], {
                type: "text/xml",
              });
              let file = new File([blob], name, { type: blob.type });
              setSelectedFile(file);
              name += "-eSigns.xml";
              setPdfName(name);
              setXml(reader.result);
              setLoadXmlComplete(true);
              setIsLoading(false);
              setSignStatus("");
            };
            reader.readAsText(new Blob([response.data]));
          },
          (error) => {
            setIsLoading(false);
            console.log(error);
          }
        );
      }
    }
  };

  /**
   * @param {object} file file from user upload
   * @param {string} type option ? (default = "e") "e" : electronicsign, "d" : digitalsign
   * @function openFileFromUpload : read file from user upload by FileReader()
   */
  const openFileFromUpload = (file, type = "e") => {
    let fileExt = file.name.split(".");
    let fileExtLenght = fileExt.length - 1;
    let name = "";
    for (let i in fileExt) {
      if (i < fileExtLenght) {
        name += fileExt[i];
      }
    }
    setPdfName(name);
    if (type === "e") {
      let reader = new FileReader();
      reader.onload = () => {
        let binaryStr = reader.result;
        name += "-eSigns.pdf";
        setPdfName(name);
        setPdfSaving(binaryStr);
      };
      reader.readAsArrayBuffer(file);
    } else if (type === "d") {
      if (fileExt[fileExtLenght] === "pdf") {
        name += "-eSigns.pdf";
        setPdfName(name);
        setUrl(URL.createObjectURL(file));
        setLoadPdfComplete(true);
      } else if (fileExt[fileExtLenght] === "xml") {
        let reader = new FileReader();
        reader.onload = () => {
          name += "-eSigns.xml";
          setPdfName(name);
          setXml(reader.result);
          setLoadXmlComplete(true);
        };
        reader.readAsText(file);
      }
      setSelectedFile(file);
    }
  };

  /** @param {object} item {item.key: doc id} */
  const deleteFile = (item = openingFile) => {
    let local_payload = payload;
    local_payload.id = item.key;
    let confirmed = window.confirm(t("Are you confirm to delete this file?"));
    if (confirmed) {
      setDefaultAlert(t("Deleting file..."));
      DeletePDF(local_payload).then((res) => {
        if (res === 200) {
          setSendSuccessAlert(t("Successful delete"));
        } else {
          setSendFailAlert(t("Fail to delete"));
        }
      });
      setDummyForUpdateList(!dummyForUpdateList);
    }
  };

  /** @param {object} item {item.key: doc id} */
  const rejectFile = (item = openingFile) => {
    let local_payload = payload;
    local_payload.id = item.key;
    let confirmed = window.confirm(t("Are you confirm to reject this file?"));
    if (confirmed) {
      setDefaultAlert(t("Reject file..."));
      RejectSign(local_payload).then((res) => {
        if (res === 200) {
          setSendSuccessAlert(t("Successful reject"));
        } else {
          setSendFailAlert(t("Fail to reject"));
        }
      });
      setDummyForUpdateList(!dummyForUpdateList);
    }
  };

  /** 
   * @param {string} flow string of flow to filter document 
      @return {Array} document that filtered by flow */
  const getFlow = (flow) => {
    let dataFlow = [];
    documents.map((item) => {
      if (item.flow === flow) {
        dataFlow.push(item);
      }
    });
    return dataFlow;
  };

  /** @return {string} status of document text */
  const setStatus = (/**@type string */ status) => {
    if (flowSelecting === "pending") {
      if (status === "received") {
        return t("Received on ");
      } else if (status === "opened") {
        return t("Opened on ");
      }
    } else if (flowSelecting === "signed") {
      return t("Signed on ");
    } else if (flowSelecting === "sent") {
      if (status === "sent") {
        return t("Sent on ");
      } else if (status === "opened") {
        return t("Opened on ");
      } else if (status === "signed") {
        return t("Signed on ");
      }
    }
    return t("Rejected on ");
  };

  /**@return {string} text of document size */
  const setSize = (/**@type number */ size) => {
    if (size >= 1073741824) {
      return "(" + (size / 1073741824).toFixed(2).toString() + "GB)";
    } else if (size >= 1048576) {
      return "(" + (size / 1048576).toFixed(2).toString() + "MB)";
    } else return "(" + (size / 1024).toFixed(2).toString() + "kB)";
  };

  /**@return {string} name of document status icon */
  const setIcon = (/**@type object */ item) => {
    let flow = item.flow;
    let status = item.status;
    let type = item.type;
    if (flow === "pending") {
      if (status === "received") {
        return "Mail";
      } else if (status === "opened") {
        return "Read";
      }
    } else if (flow === "signed" || (flow === "sent" && status === "signed")) {
      if (type === "digital") {
        return "Certificate";
      } else if (type === "electronic") {
        return "PenWorkspace";
      }
    } else if (flow === "sent") {
      if (status === "sent") {
        return "Mail";
      } else if (status === "opened") {
        return "Read";
      } else if (status === "signed") {
        if (type === "digital") {
          return "Certificate";
        } else if (type === "electronic") {
          return "PenWorkspacee";
        }
      }
    }
    return "MailUndelivered";
  };

  /**
   * @param {object} item { contains :
   *  key: file id,
   *  fileDisplayName: file name,
   *  fileExtension, fileLastModified, fileSize,
   * type: "electronic" | "digital",
   * flow: "pending" | "sign" | "sent",
   * status: "pending" | "receive" | "opened" | "signed" | "sent" | "rejected" refer to setStatus(),
   * }
   * @return {Element} each document list rendered
   * */
  const onRenderCell = (item) => {
    return (
      <div
        className={classNames.itemCell}
        data-is-focusable={true}
        horizontal
        onClick={(e) => {
          if (
            !e.target.classList.contains("MoreVertical") &&
            !e.target.parentNode.classList.contains("MoreVertical")
          ) {
            openFile(item);
          }
        }}
      >
        <FontIcon
          aria-label={setIcon(item)}
          iconName={setIcon(item)}
          className={classNames.fileIcon}
        />
        <div className={classNames.itemContent}>
          <div className={classNames.itemName}>{item.fileDisplayName}</div>
          <div>
            {setStatus(item.status) +
              moment(item.fileLastModified).format("DD/MM/YYYY  HH:mm:ss")}{" "}
            <br /> {setSize(item.fileSize)}
          </div>
        </div>
        <div
          className="MoreVertical"
          onClick={() => {
            setDetailsDrawer(item);
          }}
        >
          <Icon className={classNames.details} iconName={"MoreVertical"} />
        </div>
      </div>
    );
  };

  /** @param {object} item {item: deleting file} */
  const confirmDeleteAlert = (item) => {
    deleteFile(item);
  };

  /** @param {object} item {item: rejecting file} */
  const confirmRejectAlert = (item) => {
    rejectFile(item);
  };

  /** @returns {Array} Array of objects in bottom sheet */
  const BottomSheet = (item) => {
    let utility = [
      {
        icon: "Download",
        title: t("Download"),
        onPress: () => {
          download(item);
          setDetailsDrawer(false);
        },
      },
      // {icon: 'share', title: t('Share'), onPress: () => shareMessage()},
      {
        icon: "delete",
        title: t("Delete"),
        onPress: () => {
          confirmDeleteAlert(item);
          setDetailsDrawer(false);
        },
      },
      {
        icon: "Back",
        title: t("Return"),
        onPress: () => setDetailsDrawer(false),
      },
    ];
    if (flowSelecting === "pending") {
      return [
        {
          icon: "OpenFile",
          title: t("Open"),
          onPress: () => openFile(item),
        },
        {
          icon: "PenWorkspace",
          title: t("Sign"),
          onPress: () => {
            setOpeningSign(item);
            setDetailsDrawer(false);
          },
        },
        {
          icon: "send",
          title: t("Send"),
          onPress: () => {
            setSendFile(item);
            setDetailsDrawer(false);
          },
        },
        {
          icon: "Cancel",
          title: t("Reject"),
          onPress: () => {
            setDetailsDrawer(false);
            confirmRejectAlert(item);
          },
        },
      ].concat(utility);
    } else if (flowSelecting === "signed") {
      return [
        {
          icon: "OpenFile",
          title: t("Open"),
          onPress: () => openFile(item),
        },
        {
          icon: "send",
          title: t("Send"),
          onPress: () => {
            setSendFile(item);
            setDetailsDrawer(false);
          },
        },
      ].concat(utility);
    } else if (flowSelecting === "sent") {
      return [
        {
          icon: "OpenFile",
          title: t("Open"),
          onPress: () => openFile(item),
        },
      ].concat(utility);
    } else
      return [
        {
          icon: "Back",
          title: t("Return"),
          onPress: () => setDetailsDrawer(false),
        },
      ];
  };

  /**@return {Element} each list show option to do with documnet rendered */
  const onBottomSheetPop = (list) => {
    let listevent = list.onPress;
    return (
      <div
        onClick={listevent}
        className={classNames.itemCell}
        data-is-focusable={true}
        horizontal
      >
        <FontIcon
          aria-label={list.icon}
          iconName={list.icon}
          className={classNames.fileIcon}
        />
        <div className={classNames.itemContent}>
          <div className={classNames.itemName}>{list.title}</div>
          <div></div>
        </div>
      </div>
    );
  };

  /** @param {object} props {item: contains selected document attributes}
   * @return {Element} lists show option to do with documnet rendered
   */
  const DetailsDrawer = (props) => {
    let list = BottomSheet(props.item);
    return (
      <div className="transparentbg">
        <div className="bottom-sheet">
          <FocusZone direction={FocusZoneDirection.vertical}>
            <List items={list} onRenderCell={onBottomSheetPop} />
          </FocusZone>
        </div>
      </div>
    );
  };

  /**
   * @param {object} props if props.item => open file from exist data
   * if props.file => open file from user upload
   */
  const OpenSignPopup = (props) => {
    let passitem = props.item;
    let passfile = props.file;
    let isXml = false;
    let isEsigned = false;
    if (passitem) {
      if (passitem.type === "electronic") {
        isEsigned = true;
      }
      let fileExt = passitem.fileDisplayName.split(".");
      let fileExtLenght = fileExt.length - 1;
      if (fileExt[fileExtLenght] === "xml") {
        isXml = true;
      }
    }
    if (passfile) {
      let fileExt = passfile.name.split(".");
      let fileExtLenght = fileExt.length - 1;
      if (fileExt[fileExtLenght] === "xml") {
        isXml = true;
      }
    }

    return (
      <div className="transparentbg">
        <div className="mobile-popup">
          <div
            className="mobile-popup-close"
            onClick={() => {
              setOpeningSign(false);
              setUploadingFile(false);
            }}
          >
            <FontIcon
              aria-label="ChromeClose"
              iconName="ChromeClose"
              className={classNames.popupclose}
            />
          </div>
          <center className="mobile-popup-text">
            <h2>{t("Choose sign type")}</h2>
          </center>

          <div className="mobile-popup-button">
            <PrimaryButton
              text={t("Electronic Sign")}
              className={classNames.popupbutton}
              disabled={isXml ? true : false}
              onClick={() => {
                if (passitem) {
                  openFileWithSign(passitem, "e");
                  setOpeningSign(false);
                } else {
                  openFileFromUpload(passfile, "e");
                  setUploadingFile(false);
                }
              }}
            />
            <PrimaryButton
              className={classNames.popupbutton}
              text={t("Digital Sign")}
              disabled={isEsigned ? true : false}
              onClick={() => {
                if (passitem) {
                  openFileWithSign(passitem, "d");
                  setOpeningSign(false);
                } else {
                  openFileFromUpload(passfile, "d");
                  setUploadingFile(false);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!pdfSaving) {
      document.getElementById("lesspad").classList.remove("pdfscreen");
      document.getElementById("lesspad").classList.remove("pdfscreenfull");
    }
  }, [pdfSaving]);

  const flowMenu = {
    root: {
      alignItems: "center",
      color: "white",
      display: "flex",
      flexDirection: "column",
      height: "15vh",
      width: "33.5%",
      justifyContent: "center",
      fontSize: "large",
    },
  };

  const touchableHighlight = (status) => {
    return flowSelecting === status;
  };

  const openMenuProps = {
    items: (url
      ? [
          {
            key: "esign",
            text: t("Electronic Sign"),
            iconProps: { iconName: "PenWorkspace" },
            onClick: () => {
              openFileWithSign(openingFile);
            },
          },
        ]
      : []
    )
      .concat(
        openingFile.type !== "electronic"
          ? [
              {
                key: "dsign",
                text: t("Digital Sign"),
                iconProps: { iconName: "Certificate" },
                onClick: () => {
                  openFileWithSign(openingFile, "d");
                },
              },
            ]
          : []
      )
      .concat([
        {
          key: "reject",
          text: t("Reject"),
          iconProps: { iconName: "Cancel" },
          onClick: () => {
            rejectFile(openingFile);
          },
        },
        {
          key: "download",
          text: t("Download"),
          iconProps: { iconName: "Download" },
          onClick: () => {
            download(openingFile);
          },
        },
      ]),
  };

  /**
   * @returns {Element} context button for flow pending
   */
  const ContextButtonOpenMenu = () => {
    return (
      <DefaultButton
        text={t("Select")}
        iconProps={{ iconName: "Add", styles: { root: { fontSize: "20px" } } }}
        menuProps={openMenuProps}
        styles={{
          root: {
            fontSize: "20px",
            height: "50px",
            width: "120px",
            padding: "5%",
          },
          menuIcon: { fontSize: "15px" },
        }}
      />
    );
  };

  return (
    <Stack grow className="containers" id="lesspad">
      {sendFailAlert ? (
        <MessageBar
          actions={
            <div>
              <MessageBarButton
                onClick={() => {
                  setSendFailAlert(false);
                }}
              >
                {t("Dismiss")}
              </MessageBarButton>
            </div>
          }
          messageBarType={MessageBarType.error}
          isMultiline={false}
          styles={alertStyle}
        >
          {sendFailAlert}
        </MessageBar>
      ) : sendSuccessAlert ? (
        <MessageBar
          actions={
            <div>
              <MessageBarButton
                onClick={() => {
                  setSendSuccessAlert(false);
                }}
              >
                {t("Dismiss")}
              </MessageBarButton>
            </div>
          }
          messageBarType={MessageBarType.success}
          isMultiline={false}
          styles={alertStyle}
        >
          {sendSuccessAlert}
        </MessageBar>
      ) : defaultAlert ? (
        <MessageBar isMultiline={false} styles={alertStyle}>
          {defaultAlert}
        </MessageBar>
      ) : null}
      {pdfSaving ? (
        <PdfWindow
          file={pdfSaving}
          setPdfSaving={setPdfSaving}
          pdfName={pdfName}
          openFile={openFile}
          setOpeningFile={setOpeningFile}
          pdfId={pdfId}
          setPdfId={setPdfId}
        />
      ) : sendFile ? (
        <SendWindow
          file={sendFile}
          setSendFile={setSendFile}
          setSendFailAlert={setSendFailAlert}
          setSendSuccessAlert={setSendSuccessAlert}
        />
      ) : (loadPdfComplete || loadXmlComplete) && signStatus === "complete" ? (
        <Stack style={{ overflowX: "hidden" }}>
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 50 }}
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#4D4D4D",
              width: "100vw",
            }}
          >
            {flowSelecting === "pending" ? (
              <ContextButtonOpenMenu />
            ) : (
              <DefaultButton
                text={t("Download")}
                onClick={downloadSign}
                style={{ fontSize: "20px", height: "50px", width: "120px" }}
              ></DefaultButton>
            )}
            <DefaultButton
              text={t("Back")}
              onClick={cancelSign}
              style={{ fontSize: "20px", height: "50px", width: "120px" }}
            ></DefaultButton>
          </Stack>
          {certificateName ? (
            <Stack
              horizontal
              horizontalAlign="center"
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                background: "#C7E3F8",
                width: "100vw",
              }}
            >
              {/* <FontIcon iconName="PenWorkspace" className={checkIconClass} />
                &nbsp; */}
              <FontIcon
                iconName="SkypeCircleCheck"
                className={checkIconClass}
              />
              <span style={{ marginLeft: "8px" }}>
                {t("Digital signed by")} {certificateName} &#60;{email}&#62;
              </span>
            </Stack>
          ) : null}

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: "100vw",
                height: "100%",
              }}
            >
              <div className="mobile-all-page-container">
                {loadPdfComplete ? <PdfAllPages pdf={url} /> : null}
                {loadXmlComplete ? <XmlAllViewer xml={xml} /> : null}
              </div>
            </Stack>
          </Stack>
        </Stack>
      ) : clickSign ? (
        /* { true && */
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <div className="mobile-export-loading-center">
              <Spinner
                label={t("Digital Signing...")}
                labelPosition="down"
                styles={spinnerStyles}
              />
            </div>
          </Stack>
        </Stack>
      ) : (loadPdfComplete || loadXmlComplete) &&
        !(signStatus === "processing") &&
        !(signStatus === "complete") &&
        !(signStatus === "fail") ? (
        <Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 10 }}
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#4D4D4D",
              width: "100vw",
              alignItems: "center",
            }}
          >
            {loadPdfComplete ? (
              <Toggle
                label={<h2>PDF/A-3</h2>}
                onText={<h2>&nbsp;ON</h2>}
                offText={<h2>OFF</h2>}
                onChange={() => setTogglePdfa(!togglePdfa)}
                styles={{
                  root: { marginBottom: "0px" },
                  pill: { width: "70px", height: "25px", borderRadius: "0%" },
                  thumb: { width: "30px", height: "18px", borderRadius: "0%" },
                  label: { color: "white" },
                  text: { color: "white" },
                }}
              />
            ) : null}
            <PrimaryButton
              text={t("Document Sign")}
              onClick={sign}
              style={{ fontSize: "20px", height: "50px", width: "25vw" }}
            ></PrimaryButton>
            <DefaultButton
              text={t("Cancel")}
              onClick={cancelSign}
              style={{ fontSize: "20px", height: "50px", width: "25vw" }}
            ></DefaultButton>
          </Stack>

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: "100vw",
              }}
            >
              <div className="mobile-all-page-container">
                {loadPdfComplete && <PdfAllPages pdf={url} />}
                {loadXmlComplete && <XmlAllViewer xml={xml} />}
              </div>
            </Stack>
          </Stack>
        </Stack>
      ) : (loadPdfComplete || loadXmlComplete) && signStatus === "fail" ? (
        <Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 50 }}
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#4D4D4D",
            }}
          >
            <DefaultButton
              text={t("Back")}
              onClick={cancelSign}
            ></DefaultButton>
          </Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              background: "#C7E3F8",
            }}
          >
            <FontIcon iconName="StatusErrorFull" className={iconClass} />
            <span style={{ marginLeft: "8px" }}>
              Digital signed fail. Please try again...
            </span>
          </Stack>
        </Stack>
      ) : isLoading ? (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="loading-center">
              <Spinner
                /* size={SpinnerSize.large} */ label="กำลังดำเนินการ..."
                labelPosition="down"
                styles={spinnerStyles}
              />
            </div>
            <br />
          </Stack>
        </Stack>
      ) : (
        <Stack>
          <Stack horizontal tokens={{ childrenGap: 1 }}>
            <Stack.Item
              styles={mergeStyleSets(flowMenu, {
                root: {
                  backgroundColor: touchableHighlight("pending")
                    ? "#99f2c8"
                    : "#05386B",
                },
              })}
              onClick={() => {
                setFlowSelecting("pending");
              }}
            >
              <FontIcon
                aria-label="ReportWarning"
                iconName="ReportWarning"
                className={iconClass}
              />
              <p className="padtop10">{t("Pending")}</p>
            </Stack.Item>
            <Stack.Item
              styles={mergeStyleSets(flowMenu, {
                root: {
                  backgroundColor: touchableHighlight("signed")
                    ? "#99f2c8"
                    : "#05386B",
                },
              })}
              onClick={() => {
                setFlowSelecting("signed");
              }}
            >
              <FontIcon
                aria-label="EditMirrored"
                iconName="EditMirrored"
                className={iconClass}
              />
              <p className="padtop10">{t("Signed")}</p>
            </Stack.Item>
            <Stack.Item
              styles={mergeStyleSets(flowMenu, {
                root: {
                  backgroundColor: touchableHighlight("sent")
                    ? "#99f2c8"
                    : "#05386B",
                },
              })}
              onClick={() => {
                setFlowSelecting("sent");
              }}
            >
              <FontIcon
                aria-label="Send"
                iconName="Send"
                className={iconClass}
              />
              <p className="padtop10">{t("Sent")}</p>
            </Stack.Item>
          </Stack>
          <Stack horizontal className={classNames.filter}>
            <p className="mobile-filter-header">{t("Filter")}</p>
            <label htmlFor="month-filter" className="mobile-filter-text">
              {t("Month")}
            </label>
            <select
              id="month-filter"
              name="month-filter"
              className="mobile-filter-dropdown"
              defaultValue={monthSelecting}
            >
              {Array.from({ length: 12 }, (_, i) => i + 1).map((number) => {
                /**find current month and selected */
                return (
                  <option value={number} key={number.toString()}>
                    {i18n.language === "th"
                      ? new Date(number.toString()).toLocaleString("th-TH", {
                          month: "short",
                        })
                      : new Date(number.toString()).toLocaleString("en-EN", {
                          month: "short",
                        })}
                  </option>
                );
              })}
            </select>
            <label htmlFor="year-filter" className="mobile-filter-text">
              {t("Year")}
            </label>
            <select
              id="year-filter"
              name="year-filter"
              className="mobile-filter-dropdown"
              defaultValue={yearSelecting}
            >
              {Array.from(
                {
                  length:
                    (parseInt(moment().format("YYYY")) /**stop */ -
                      2021) /**start */ /
                      1 /**step */ +
                    1,
                },
                (_, i) => 2021 /**start */ + i * 1 /**step */
              ).map((number) => {
                let trans = i18n.language === "th" ? 543 : 0;
                return (
                  <option value={number} key={number.toString()}>
                    {number + trans}
                  </option>
                );
              })}
            </select>
          </Stack>
          {noData && !loadingDocument ? (
            <center className="mobile-nodata">
              <p>{t("No document available")}</p>
            </center>
          ) : (
            <FocusZone direction={FocusZoneDirection.vertical}>
              <List
                items={items}
                onRenderCell={onRenderCell}
                className={classNames.list}
              />
            </FocusZone>
          )}

          <div className="mobile-upload">
            <label htmlFor="uploadfile" className="mobile-upload-label">
              +
            </label>
            <input
              id="uploadfile"
              type="file"
              name="uploadfile"
              className="mobile-uploadfile"
            ></input>
          </div>
          {detailsDrawer ? (
            <DetailsDrawer item={detailsDrawer} />
          ) : openingSign ? (
            <OpenSignPopup item={openingSign} />
          ) : uploadingFile ? (
            <OpenSignPopup file={uploadingFile} />
          ) : null}
        </Stack>
      )}
    </Stack>
  );
}
export default MobileDocumentWindow;
