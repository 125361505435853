import axios from "axios";
import { silentRequest } from "../authConfig";

/**
 * @function DigitalSignPDFA digital sign for pdfa
 * @param {Object} payload {instance, account, file}
 * @returns {Object} result
 */
export const DigitalSignPDFA = (payload) => {   
  return new Promise((resolve, reject) => {
    payload.instance
      .acquireTokenSilent({ ...silentRequest, account: payload.account })
      .then((tokenResponse) => {
        let formData = new FormData();

        formData.append("file", payload.file);
        axios
          .post("https://ws.esigns.cloud/documents/pdfa/digitalsign",formData, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            resolve(res);
          });
      })
      .catch((error) => {
        console.log(error);
        payload.instance.acquireTokenRedirect(silentRequest);
        reject();
      });
  });
};