import axios from "axios";
import { silentRequest } from "../authConfig";

/** 
 * @function ListFilePDF list user's file from all flow
 * @param {Object} payload {instance, account, month: number, year: number}
 * @returns {Promise} promise contains documents
 */
export const ListFilePDF = (payload) => {  
  /**
   * @function toDigitString
   * @param {number} number 
   * @param {number} digit 
   * @returns {string} string of month or year (include 0 on front if have only 1 digit for month)
   */ 
  const toDigitString = (number, digit = 2) => {
    let string = "";
    for(let i=1; number < 10 * (digit - i); i++){
      string += "0";
    }
    string += number.toString();
    return string;
  }
  return new Promise((resolve, reject) => {
    payload.instance
      .acquireTokenSilent({ ...silentRequest, account: payload.account })
      .then(async (tokenResponse) => {
        // console.log("query: ", payload.month, payload.year);
        if(payload.month && payload.year){
          let month = toDigitString(payload.month);
          let year = toDigitString(payload.year, 4);
          let from_date = year + "-" + month + "-01";
          let to_date = "";
          if(payload.month === 12){
            month = "01";
            year = toDigitString(payload.year + 1, 4);
          }
          else {
            month = toDigitString(payload.month + 1);
            year = toDigitString(payload.year, 4);
          }
          to_date = year + "-" + month + "-01";
          axios
          .get("https://ws.esigns.cloud/documents?type=all&from=" + from_date + "&to=" + to_date, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then((res) => {
            resolve(res);
          });
        }
        else {
          axios
          .get("https://ws.esigns.cloud/documents?type=all", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then((res) => {
            resolve(res);
          });
        }
        
      })
      .catch((error) => {
        console.log(error);
        payload.instance.acquireTokenRedirect(silentRequest);
        reject();
      });
  });
};