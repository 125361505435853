import React, { useState, useCallback } from "react";
import axios from "axios";

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../../../authConfig";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import {
  Stack,
  Icon,
  PrimaryButton,
  DefaultButton,
  TextField,
  FocusTrapZone,
  Spinner,
  mergeStyleSets
} from "@fluentui/react";

import { useDropzone } from "react-dropzone";
import styled from "styled-components";

var forge = require("node-forge");

const API_URL = process.env.REACT_APP_API_URL;

//Dropzone Style

const getColor = (props) => {
  if (props.isDragAccept) {
    return "#00e676";
  }
  if (props.isDragReject) {
    return "#ff1744";
  }
  if (props.isDragActive) {
    return "#2196f3";
  }
  return "rgb(134, 134, 134)";
};

const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: ${(props) => getColor(props)};
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 5px;
`;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const classList = mergeStyleSets({
  flexCenter : {
    alignItems: "center"
  },
  headerLabel : {
    marginLeft: "0%", 
    marginTop: "5%",
    width: "90vw",
    fontSize: "x-large"
  },
  describeLabel : {
    color: "gray", 
    marginBottom: "5%",
    width: "90vw"
  },
  backButton : {
    marginTop: "10% !important" 
  },
  dropzone : {
    width: "90vw"
  }
});

const MobileP12 = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  const history = useHistory();
  const { t } = useTranslation();

  const [selectedFile, setSelectedFile] = useState(null);
  const [loadP12Complete, setLoadP12Complete] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [uploadStatus, setUploadStatus] = useState("");

  const FileDropzone = () => {
    //Dropzone
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      isDragAccept,
      isDragReject,
      open,
    } = useDropzone({
      accept: ".pfx, .p12, application/x-pkcs12, application/pkcs12",
      // Disable click and keydown behavior
      noClick: true,
      noKeyboard: true,
      multiple: false,
      minSize: 0,
      maxSize: 20971520,
      onDrop: (files) => {
        console.log(files);
        console.log("File[0]: ", files[0]);

        if (
          files[0].type === "application/x-pkcs12" ||
          files[0].type === "application/pkcs12"
        ) {
          setSelectedFile(files[0]);
          console.log("Set selected file complete.");

          //setUrl(URL.createObjectURL(files[0]));

          setLoadP12Complete(true);
          //setExtension('pdf');
        }
      },
    });

    return (
      <div>
        <div>
          <Container
            {...getRootProps({
              isDragActive,
              isDragAccept,
              isDragReject,
            })}
          >
            <input {...getInputProps()} />
            <br />
            <div style={{ fontSize: "80px", color: "rgba(45, 45, 83, 0.808)" }}>
              <i className="fad fa-file-certificate"></i>
            </div>
            <br />
            <Stack horizontal horizontalAlign="center">
              <br />
              <PrimaryButton
                style={{ fontSize: "20px", height: "50px", width: "150px" }}
                onClick={open}
                text={t("Choose File")}
              />

              <br />
              <br />
              <br />
              <br />
            </Stack>
            <Stack horizontal horizontalAlign="center">
              <br />
              <br />

              <span
                style={{
                  fontSize: "25px",
                  color: "rgb(151, 151, 151)",
                  fontWeight: "500",
                }}
              >
                {t("Drag or Click here to import .p12 or .pfx file")}
              </span>
            </Stack>
          </Container>
        </div>
      </div>
    );
  };

  const onDrop = useCallback((files) => {
    // Do something with the files
    console.log(files);
    //setname(files.path)
    var reader = new FileReader();
    reader.onload = function (e) {
      var fileContent = e.target.result;

      // decrypt p12 using the password returned by getPassword(), the password should be protected and not hard coded
      try {
        // get p12 as ASN.1 object
        var p12Asn1 = forge.asn1.fromDer(fileContent);

        var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, "leceiptleceipt");

        // get bags by type
        var certBags = p12.getBags({ bagType: forge.pki.oids.certBag });
        var pkeyBags = p12.getBags({
          bagType: forge.pki.oids.pkcs8ShroudedKeyBag,
        });
        // fetching certBag
        var certBag = certBags[forge.pki.oids.certBag][0];
        // fetching keyBag
        var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];

        if (certBag && keybag) {
          console.log("on Drop cerBag :", certBag.cert);
          console.log("Success then upload file!");
          //setLoad(true)
        }
      } catch (error) {
        console.log(error);
        console.log("Fail!");
      }
    };
    reader.readAsBinaryString(files[0]);
  }, []);

  const { acceptfile, getRootProps, getInputProps } = useDropzone({ onDrop });

  const openFile = () => {
    let reader = new FileReader();
    reader.onload = function (e) {
      let fileContent = e.target.result;
      //console.log('file content: ', fileContent);
      //setname(e.path)

      // decrypt p12 using the password returned by getPassword(), the password should be protected and not hard coded
      try {
        // get p12 as ASN.1 object
        var p12Asn1 = forge.asn1.fromDer(fileContent);

        var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, password);

        // get bags by type
        var certBags = p12.getBags({ bagType: forge.pki.oids.certBag });
        var pkeyBags = p12.getBags({
          bagType: forge.pki.oids.pkcs8ShroudedKeyBag,
        });
        // fetching certBag
        var certBag = certBags[forge.pki.oids.certBag][0];
        // fetching keyBag
        var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
        // generate pem from private key
        //var privateKeyPem = forge.pki.privateKeyToPem(keybag.key);
        // generate pem from cert
        //var certificate = forge.pki.certificateToPem(certBag.cert);

        if (certBag && keybag) {
          //console.log(certBag.cert);
          console.log("Success then upload file!");
          //setStatus('Loading..');

          setIsLoading(true);
          //setUploadStatus('fail');

          uploadFile();
        }
      } catch (error) {
        console.log(error);
        console.log("Can not open file.");
        setPasswordError(t("Wrong password. Please try again..."));
      }
    };

    reader.readAsBinaryString(selectedFile);
  };

  const uploadFile = () => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        // console.log("Access token: ", tokenResponse.accessToken);
        // console.log("Token: ", tokenResponse);

        let promises = [];

        //Encode base64
        let buff = Buffer.from(password);
        let base64pwd = buff.toString("base64");

        //files.forEach(file => {

        //console.log(file.path);
        let formData = new FormData();
        //formData.append('requestId', requestId);
        formData.append("file", selectedFile);
        formData.append("password", base64pwd);
        //console.log(formData);

        promises.push(
          axios.post(API_URL + "/certificates", formData, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
              "Content-Type": "multipart/form-data",
              //'OID': 'b5cf38c9-5e52-4bd3-b737-caf5c6d30635'
              //'api-key': '6c8921222ff946479e523edeca961f51'
            },
          })
        );

        //});

        Promise.all(promises).then(function (results) {
          results.forEach(function (response) {
            // console.log("Response upload: ", response.data);
            //setLoad(false)
            //setPass(false)
            //setPwd('')
            //setStatus('Upload .pfx/.p12 file success!');
            //Message()

            if (response.data.id) {
              setIsLoading(false);
              setUploadStatus("successful");
            } else {
              setIsLoading(false);
              setUploadStatus("fail");
            }
          });
        });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const clearData = () => {
    setSelectedFile(null);
    setIsLoading(false);
    setUploadStatus("");
    setPassword("");
    setPasswordError("");
    setLoadP12Complete(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    openFile();
  };

  return (
    <Stack className={classList.flexCenter}>
      <h1 className={classList.headerLabel}>
        <Icon iconName="Certificate" style={{}} /> {t("My Digital Signature")}
      </h1>
      <h4 className={classList.describeLabel}>
        {t("A certificate issued by a certificate authority")}
      </h4>

      {!loadP12Complete && !isLoading && (
        <Stack className={classList.dropzone}>
          <FileDropzone />
          <Stack horizontal horizontalAlign="center" className={classList.backButton}>
            <DefaultButton
              style={{ fontSize: "20px", height: "50px" }}
              onClick={() => {
                history.push({
                  pathname: "/certificates",
                  state: {},
                });
              }}
            >
              {t("Back")}
            </DefaultButton>
          </Stack>
        </Stack>
      )}

      {loadP12Complete && !isLoading && uploadStatus === "" && (
        <FocusTrapZone disabled={false}>
          <Stack horizontalAlign="center">
            <br />
            <br />
            <Stack
              style={{
                border: "1px solid rgb(134, 134, 134)",
                width: "350px",
                backgroundColor: "#FAFAFA",
              }}
            >
              <br />
              <br />

              <Stack horizontal horizontalAlign="center">
                <i
                  class="fas fa-lock-alt"
                  style={{ fontSize: "45px", color: "rgb(112, 112, 112)" }}
                ></i>
              </Stack>

              <br />
              <form onSubmit={handleSubmit}>
                <Stack horizontal horizontalAlign="center">
                  <TextField
                    label={t("Password for open .p12 or .pfx file")}
                    type="password"
                    canRevealPassword
                    revealPasswordAriaLabel="Show password"
                    required
                    onChange={(e) => {
                      setPassword(e.target.value);
                      setPasswordError("");
                    }}
                    value={password}
                    errorMessage={passwordError}
                  />
                </Stack>

                <br />
                <br />
                <br />
                <Stack
                  horizontal
                  horizontalAlign="center"
                  tokens={{ childrenGap: "30px" }}
                >
                  <PrimaryButton
                    style={{ fontSize: "20px", height: "50px" }}
                    type="submit"
                  >
                    {t("Upload")}
                  </PrimaryButton>

                  <DefaultButton
                    style={{ fontSize: "20px", height: "50px" }}
                    onClick={() => {
                      clearData();
                    }}
                  >
                    {t("Back")}
                  </DefaultButton>
                </Stack>
              </form>
              <br />
              <br />
            </Stack>
          </Stack>
        </FocusTrapZone>
      )}

      {isLoading && (
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="certificates-loading-center">
              <Spinner
                /* size={SpinnerSize.large} */ label={t("Processing...")}
                labelPosition="down"
                styles={spinnerStyles}
              />
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {uploadStatus === "successful" && (
        /* { true && */

        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: "1px solid rgb(134, 134, 134)",
              width: "300px",
              backgroundColor: "#FAFAFA",
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: "0px", color: "#0078D4" }}>
                {t("Success!")}
              </h1>
            </Stack>
            {/* <br/> */}
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/success.gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: "20px", height: "50px" }}
                onClick={() => {
                  //setClickAdd(true)

                  history.push({
                    pathname: "/certificates",
                    //search: '?update=true',  // query string
                    state: {},
                  });

                  clearData();
                }}
              >
                {t("Certificate Detail")}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
            {/*  <br/> */}
          </Stack>
        </Stack>
      )}

      {uploadStatus === "fail" && (
        <Stack horizontal horizontalAlign="center">
          <Stack
            style={{
              border: "1px solid rgb(134, 134, 134)",
              width: "300px",
              backgroundColor: "#FAFAFA",
            }}
          >
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <h1 style={{ margin: "0px", color: "#F25139" }}>{t("Fail!")}</h1>
            </Stack>
            {/* <br/> */}
            <Stack horizontal horizontalAlign="center">
              <img height="120" src="/images/fail.gif" />
            </Stack>
            <br />
            <br />
            <Stack horizontal horizontalAlign="center">
              <PrimaryButton
                style={{ fontSize: "20px", height: "50px" }}
                onClick={() => {
                  //setClickAdd(true)
                  clearData();
                  history.push({
                    pathname: "/certificates/add/p12",
                    //search: '?update=true',  // query string
                    state: {},
                  });
                }}
              >
                {t("Try Again")}
              </PrimaryButton>
            </Stack>
            <br />
            <br />
            {/*  <br/> */}
          </Stack>
        </Stack>
      )}
    </Stack>
  );
};

export default MobileP12;
