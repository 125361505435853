import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";
import { FontIcon, MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import moment from "moment";
import "./css/MobileForm.css";

import { SendFile } from "../../../api_function/sendFile";

export const SendWindow = (props) => {
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [email, setEmail] = useState(""); //sender email
  const [receiver, setReceiver] = useState(""); //receiver email
  const [message, setMessage] = useState("");
  const [statusText, setStatusText] = useState("");
  const [sending, setSending] = useState(false);
  const [sendFailAlert, setSendFailAlert] = useState(false);

  initializeIcons();
  const iconClass = mergeStyles({
    fontSize: 50,
  });
  const iconxxClass = mergeStyles({
    fontSize: "xx-large",
  });

  const alertStyle = {
    root: {
      position: "fixed",
      top: "60px",
      left: "0px",
      width: "100vw",
      height: "5vh",
      zIndex: 10
    },
    iconContainer: {
      alignItems: "center"
    },
    text: {
      alignItems: "center"
    },
    innerText: {
      fontSize: "larger"
    }
  }

  useEffect(() => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        setName(
          tokenResponse.account.idTokenClaims.given_name +
            " " +
            tokenResponse.account.idTokenClaims.family_name
        );
        setEmail(tokenResponse.account.idTokenClaims.emails[0]);
      });
    let status = props.file.status;
    let flow = props.file.flow;
    if (flow === "pending") {
      if (status === "received") {
        setStatusText(t("Recieved on"));
      } else if (status === "opened") {
        setStatusText(t("Opened on"));
      }
    } else if (flow === "signed") {
      setStatusText(t("Signed on"));
    } else if (flow === "sent") {
      if (status === "sent") {
        setStatusText(t("Sent on"));
      } else if (status === "opened") {
        setStatusText(t("Opened on"));
      }
    } else {
      setStatusText(t("Rejected on"));
    }

    document.getElementById("mobile-back").onclick = function () {
      props.setSendFile(false);
    };
    document.getElementById("mobile-send").addEventListener("click", submitForm);

    function submitForm() {
      setSending(true);
    }
  }, []);

  useEffect(() => {
    if (sending) {
      if (validate()[0] && validate()[1]) {
        let payload = {
          instance: instance,
          account: account,
          id: props.file.key,
          email: email,
          message: message,
          receiver: receiver,
          name: name,
        };
        SendFile(payload).then((res) => {
          if (res.status === 200) {
            props.setSendSuccessAlert(t("Send Success"));
          } else {
            props.setSendFailAlert(t("Something went wrong, please try again"));
          }
          setSending(false);
          props.setSendFile(false);
        });
      } else {
        setSending(false);
      }
    }
  }, [sending]);

  const validate = () => {
    let mail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validateEmail = mail.test(receiver);
    if (!validateEmail || !message) {
      setSendFailAlert(t("Invalid input"));
      // alert(t("Invalid input"));
      if (!validateEmail) {
        document.getElementById("to").style.borderColor = "crimson";
      } else {
        document.getElementById("to").style.borderColor = "black";
      }
      if (!message) {
        document.getElementById("message").style.borderColor = "crimson";
      } else {
        document.getElementById("message").style.borderColor = "black";
      }
    }
    return [validateEmail ? true : false, message ? true : false];
  };

  const DocumentIcon = () => {
    let type = props.file.type;
    if (type === "electronic") {
      return (
        <FontIcon
          aria-label="EditMirrored"
          iconName="EditMirrored"
          className={iconClass}
        />
      );
    } else if (type === "digital") {
      return (
        <FontIcon
          aria-label="Certificate"
          iconName="Certificate"
          className={iconClass}
        />
      );
    } else
      return (
        <FontIcon
          aria-label="StatusCircleQuestionMark"
          iconName="StatusCircleQuestionMark"
          className={iconClass}
        />
      );
  };

  return (
    <div className="mobile-forms">
      {
        sendFailAlert ? (
          <MessageBar
          actions={
            <div>
              <MessageBarButton onClick={() => {setSendFailAlert(false)}}>{t("Dismiss")}</MessageBarButton>
            </div>
          }
          messageBarType={MessageBarType.error}
          isMultiline={false}
          styles={alertStyle}
        >
          {sendFailAlert}
        </MessageBar>
        ) : null
      }
      <h1 className="mobile-h1 mobile-send-nav">
        <span className="mobile-send-header">
          <FontIcon
            aria-label="Back"
            iconName="Back"
            className={(iconxxClass, "mobile-back")}
            id="mobile-back"
          />{" "}
          {t("Back")}
        </span>

        {sending ? (
          <span className="mobile-send" id="mobile-send">
            {t("Sending...")}
          </span>
        ) : (
          <span className="mobile-send" id="mobile-send">
            {t("Submit")}{" "}
            <FontIcon
              aria-label="Send"
              iconName="Send"
              className={(iconxxClass, "mobile-send-icon")}
            />
          </span>
        )}
      </h1>
      <div className="mobile-divider" />
      <div>
        <label className="mobile-label" for="from">
          {t("From")}:
        </label>
        <br />
        <input
          className="mobile-input"
          type="email"
          name="from"
          disabled={true}
          value={email}
        />
      </div>
      <div>
        <label className="mobile-label" for="to">
          {t("To")}:
        </label>
        <br />
        <input
          className="mobile-input"
          type="email"
          name="to"
          id="to"
          value={receiver}
          onChange={(event) => setReceiver(event.target.value)}
        />
      </div>
      <div className="mobile-divider" />
      <div className="mobile-frame">
        <div className="mobile-icon">
          <DocumentIcon />
        </div>
        <div>
          <h3 className="mobile-h3">
            {props.file.fileDisplayName || "Untitled"}
          </h3>
          <p>
            {statusText +
              " " +
              moment(props.file.fileLastModified).format("DD/MM/YYYY  HH:mm")}
          </p>
        </div>
      </div>
      <div className="mobile-divider" />
      <div>
        <label className="mobile-label">{t("Message")}:</label>
        <br />
        <textarea
          className="mobile-textarea"
          name="message"
          id="message"
          rows="5"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
      </div>
    </div>
  );
};
