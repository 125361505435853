import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
//import reportWebVitals from './reportWebVitals';

import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./authConfig";

//import Modal from 'react-modal'
import "./fonts/Book_Akhanake.ttf"
import "./fonts/milk tea-normal.ttf"

import './Language/i18next'
const msalInstance = new PublicClientApplication(msalConfig);
//Modal.setAppElement('#root')

if (process.env.NODE_ENV !== "development") {
    console.log = function () {};
}

ReactDOM.render(
 
    <React.StrictMode>
        <App pca={msalInstance} />
    </React.StrictMode>,
 
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals();
