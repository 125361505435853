import React, { useState, useEffect, useCallback } from "react";

import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";

import { useDropzone } from "react-dropzone";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import moment, { months } from "moment";
import { Pagination } from "@uifabric/experiments/lib/Pagination";
import paginate from "jw-paginate";
import {
  Toggle,
  Pivot,
  PivotItem,
  MessageBar,
  MessageBarButton,
  MessageBarType,
} from "@fluentui/react";

import axios from "axios";

import {
  ShimmeredDetailsList,
  SelectionMode,
  DetailsListLayoutMode,
  DefaultButton,
  Stack,
  ActionButton,
  mergeStyles,
  mergeStyleSets,
  PrimaryButton,
  Spinner,
  FontIcon,
} from "@fluentui/react";

import "./DigitalSignature.css";

import PdfAllPages from "./PdfAllPages";
import XmlAllViewer from "./XmlAllViewer";
import { DeletePDF } from "../../../api_function/deletePDF";
import { RejectSign } from "../../../api_function/rejectSign";
import { SendWindow } from "../OpenPDF/SendWindow";
import { ListFilePDF } from "../../../api_function/listFilePDF";

import { useSessionStorage } from "../../../session_storage/Session";
import { useLocalStorage } from "../../../local_storage/LocalStorage";

const API_URL = process.env.REACT_APP_API_URL;

// Dropzone style
const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 5px;
  border-color: rgb(134, 134, 134);
  border-style: dashed;
  background-color: #fafafa;
  color: #bdbdbd;
  outline: none;
  transition: border 0.24s ease-in-out;
  margin-top: 5px;
  height: 350px;
`;

// Processing dialog style
const spinnerStyles = {
  circle: {
    height: 56,
    width: 56,
    borderWidth: 4,
  },
};

const alertStyle = {
  root: {
    position: "fixed",
    top: "60px",
    left: "250px",
    width: "calc(100vw - 250px)",
    height: "5vh",
    zIndex: 10,
  },
  iconContainer: {
    alignItems: "center",
  },
  text: {
    alignItems: "center",
  },
  innerText: {
    fontSize: "larger",
  },
};

// Icon style

const iconClass = mergeStyles({
  fontSize: 120,
  height: 120,
  width: 120,
  margin: "0 60px",
  //alignContent: 'center'
});

const classNames = mergeStyleSets({
  fileIconHeaderIcon: {
    padding: 0,
    fontSize: "16px",
  },
  fileIconCell: {
    textAlign: "center",
    selectors: {
      "&:before": {
        content: ".",
        display: "inline-block",
        verticalAlign: "middle",
        height: "100%",
        width: "0px",
        visibility: "hidden",
      },
    },
  },
  fileIconImg: {
    verticalAlign: "middle",
    maxHeight: "16px",
    maxWidth: "16px",
  },
  controlWrapper: {
    display: "flex",
    flexWrap: "wrap",
  },
  exampleToggle: {
    display: "inline-block",
    marginBottom: "10px",
    marginRight: "30px",
  },
  selectionDetails: {
    marginBottom: "20px",
  },
  blue: [
    {
      color: "#0078D4",
    },
    iconClass,
  ],
  filter: {
    minWidth: "320px",
    height: "44px",
    position: "relative",
    top: "0px",
    left: "-320px",
    textAlign: "baseline",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "'CSChatThaiUI', sans-serif !important",
    fontSize: "medium",
    color: "rgb(50, 49, 48)",
  },
});

//Check icon style
const checkIconClass = mergeStyles({
  fontSize: 18,
  marginTop: "2px",
  color: "green",
});

const errorIconClass = mergeStyles({
  fontSize: 18,
  marginTop: "2px",
  color: "red",
});

const DigitalSignature = (props) => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});

  var payload = {
    instance: instance,
    account: account,
  };

  const { t, i18n } = useTranslation();

  const [documents, setDocuments] = useState([]);
  const [items, setItems] = useState([]);
  const [loadDataComplete, setLoadDataComplete] = useState(false);
  const [loadPdfComplete, setLoadPdfComplete] = useState(false);
  const [loadXmlComplete, setLoadXmlComplete] = useState(false);
  const [clickSign, setClickSign] = useState(false);
  const [signStatus, setSignStatus] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const [pageData, setPageData] = useState({});
  const [pageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedFile, setSelectedFile] = useState(null);
  const [openingFile, setOpeningFile] = useState(false);
  const [url, setUrl] = useState("");
  const [xml, setXml] = useState("");
  const [togglePdfa, setTogglePdfa] = useState(false);
  const [sendFile, setSendFile] = useState(false);

  const [certificateName, setCertificateName] = useState("");
  const [email, setEmail] = useState("");

  const [pendingItems, setPendingItems] = useState([]);
  const [signedItems, setSignedItems] = useState([]);
  const [sentItems, setSentItems] = useState([]);

  const [flowSelecting, setFlowSelecting] = useSessionStorage(
    "flowSelecting",
    "pending"
  );
  const [monthSelecting, setMonthSelecting] = useLocalStorage(
    "monthSelecting",
    parseInt(moment().format("MM"))
  );
  const [yearSelecting, setYearSelecting] = useLocalStorage(
    "yearSelecting",
    parseInt(moment().format("YYYY"))
  );

  const [defaultAlert, setDefaultAlert] = useState(false);
  const [sendSuccessAlert, setSendSuccessAlert] = useState(false);
  const [sendFailAlert, setSendFailAlert] = useState(false);

  const [, setPendingEsign] = useSessionStorage("pendingEsign", 0);
  const [, setPendingDigitalsign] = useSessionStorage("pendingDigitalsign", 0);

  const [dummyForUpdateList, setDummyForUpdateList] = useState(false);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
    open,
  } = useDropzone({
    accept: "application/pdf, application/xml, text/xml",
    // Disable click and keydown behavior
    noClick: true,
    noKeyboard: true,
    multiple: true,
    onDrop: useCallback((files) => {
      if (files[1]) {
        alert(t("Please input only one file at once."));
        return;
      }
      //extension file validation
      let fileExt = files[0].name.split(".");
      let fileExtLenght = fileExt.length - 1;
      if (togglePdfa) {
        if (fileExt[fileExtLenght] !== "pdf") {
          alert(t("Please input .pdf file only."));
          return;
        }
      } else {
        if (
          fileExt[fileExtLenght] !== "pdf" &&
          fileExt[fileExtLenght] !== "xml"
        ) {
          alert(t("Please input .pdf or .xml file only."));
          return;
        }
      }
      setSelectedFile(files[0]);

      if (files[0].type === "application/pdf") {
        setUrl(URL.createObjectURL(files[0]));

        setLoadPdfComplete(true);
      } else if (
        files[0].type === "application/xml" ||
        files[0].type === "text/xml"
      ) {
        let reader = new FileReader();

        reader.onload = () => {
          setXml(reader.result);

          setLoadXmlComplete(true);
        };
        reader.readAsText(files[0]);
      }
    }, []),
  });

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  //   const toBase64 = (file) =>
  //     new Promise((resolve, reject) => {
  //       const reader = new FileReader();
  //       reader.readAsDataURL(file);
  //       reader.onload = () => resolve(reader.result);
  //       reader.onerror = (error) => reject(error);
  //     });

  //   const getBase64 = (file) => {
  //     var reader = new FileReader();
  //     reader.readAsDataURL(file);
  //     reader.onload = function () {
  //       console.log(reader.result);
  //       //return reader.result;
  //     };
  //     reader.onerror = function (error) {
  //       console.log("Error: ", error);
  //     };
  //   };

  useEffect(() => {
    if (
      flowSelecting !== JSON.parse(sessionStorage.getItem("flowSelecting")) &&
      sessionStorage.getItem("flowSelecting")
    ) {
      setFlowSelecting(JSON.parse(sessionStorage.getItem("flowSelecting")));
    }
    if (
      monthSelecting !== JSON.parse(sessionStorage.getItem("monthSelecting")) &&
      sessionStorage.getItem("monthSelecting")
    ) {
      setMonthSelecting(JSON.parse(sessionStorage.getItem("monthSelecting")));
    }
    if (
      yearSelecting !== JSON.parse(sessionStorage.getItem("yearSelecting")) &&
      sessionStorage.getItem("yearSelecting")
    ) {
      setYearSelecting(JSON.parse(sessionStorage.getItem("yearSelecting")));
    }
  }, [clickSign]);

  const sign = () => {
    setClickSign(true);
    setMonthSelecting(moment().format("MM"));
    setYearSelecting(moment().format("YYYY"));
    setFlowSelecting("signed");
    if (loadPdfComplete) {
      if (togglePdfa) {
        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            let promises = [];
            let formData = new FormData();

            formData.append("file", selectedFile);

            promises.push(
              axios.post(API_URL + "/documents/pdfa/digitalsign ", formData, {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                  "Content-Type": "multipart/form-data",
                },
              })
            );
            Promise.all(promises).then(async function (results) {
              results.forEach(function (response) {
                console.log("Response upload: ", response.data);
                setSignStatus(response.data.status);
                checkProcess(response.data.id);
              });
            });
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      } else {
        instance
          .acquireTokenSilent({ ...silentRequest, account: account })
          .then((tokenResponse) => {
            let promises = [];
            let formData = new FormData();

            formData.append("file", selectedFile);
            console.log(selectedFile);

            promises.push(
              axios.post(API_URL + "/documents/pdf/digitalsign", formData, {
                headers: {
                  Authorization: "Bearer " + tokenResponse.accessToken,
                  "Content-Type": "multipart/form-data",
                },
              })
            );
            Promise.all(promises).then(async function (results) {
              results.forEach(function (response) {
                console.log("Response upload: ", response.data);
                setSignStatus(response.data.status);
                checkProcess(response.data.id);
              });
            });
          })
          .catch((error) => {
            //Acquire token silent failure, and send an interactive request
            console.log(error);
            instance.acquireTokenRedirect(silentRequest);
          });
      }
    } else if (loadXmlComplete) {
      instance
        .acquireTokenSilent({ ...silentRequest, account: account })
        .then((tokenResponse) => {
          let promises = [];
          let formData = new FormData();

          formData.append("file", selectedFile);

          promises.push(
            axios.post(API_URL + "/documents/xml/digitalsign", formData, {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
                "Content-Type": "multipart/form-data",
              },
            })
          );
          Promise.all(promises).then(async function (results) {
            results.forEach(function (response) {
              console.log("Response upload: ", response.data);
              setSignStatus(response.data.status);
              checkProcess(response.data.id);
            });
          });
        })
        .catch((error) => {
          //Acquire token silent failure, and send an interactive request
          console.log(error);
          instance.acquireTokenRedirect(silentRequest);
        });
    }
  };

  const cancelSign = () => {
    setUrl(false);
    setLoadPdfComplete(false);
    setXml(false);
    setLoadXmlComplete(false);

    setSignStatus("");
    setSelectedFile(false);
    setOpeningFile(false);
    setLoadDataComplete(false);
  };

  const downloadSign = () => {
    if (openingFile) {
      download(openingFile);
    }
  };

  const checkProcess = (id) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        var interval = setInterval(function () {
          axios
            .get(API_URL + "/jobs/" + id, {
              headers: {
                Authorization: "Bearer " + tokenResponse.accessToken,
              },
            })
            .then(
              (res) => {
                if (res.data.status === "complete") {
                  console.log("Sign complete!");
                  console.log("Clear interval!");
                  console.log("Document data: ", res.data);

                  setOpeningFile({
                    key: res.data.fileId,
                    fileDisplayName: selectedFile.name,
                  });

                  getDocument(res.data.fileId);

                  getFile(res.data.fileId);

                  clearInterval(interval);
                } else if (res.data.status === "fail") {
                  setClickSign(false);
                  setSignStatus(res.data.status);
                  clearInterval(interval);
                } else {
                  console.log("Check status: ", res.data);
                }
              },
              (error) => {
                console.log(error);
              }
            );
        }, 2000);
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const getDocument = (documentId) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + "/documents/" + documentId, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              setCertificateName(response.data.signatures[0].certificateName);
              setEmail(response.data.signatures[0].email);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const getFile = (fileId) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + "/files/" + fileId, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
            responseType: "blob",
          })
          .then(
            (response) => {
              //console.log(response)

              if (loadPdfComplete) {
                setUrl(window.URL.createObjectURL(new Blob([response.data])));

                setLoadPdfComplete(true);

                setClickSign(false);
                setSignStatus("complete");
              } else if (loadXmlComplete) {
                let reader = new FileReader();

                reader.onload = () => {
                  console.log("Xml :", reader.result);

                  setXml(reader.result);

                  setLoadXmlComplete(true);
                  //setExtension('xml');

                  setClickSign(false);
                  setSignStatus("complete");
                };

                reader.readAsText(new Blob([response.data]));
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const onPageChange = (selectedPageIndex) => {
    console.log("selectedPageIndex: ", selectedPageIndex);
    setCurrentPage(selectedPageIndex + 1);
  };

  const download = (item) => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + "/files/" + item.key, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
            responseType: "blob",
          })
          .then(
            (response) => {
              //console.log(response)

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;

              link.setAttribute("download", item.fileDisplayName);
              document.body.appendChild(link);
              //link.download = `${ID.file.displayName}`
              link.click();
              link.remove();
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const openFile = (item) => {
    setOpeningFile(item);
    setIsLoading(true);

    let name = "";
    let fileExt = item.fileDisplayName.split(".");
    let fileExtLenght = fileExt.length - 1;
    for (let i in fileExt) {
      if (i < fileExtLenght) {
        name += fileExt[i];
      }
    }

    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        axios
          .get(API_URL + "/files/" + item.key, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
            responseType: "blob",
          })
          .then(
            (response) => {
              // console.log(response)

              if (item.fileExtension === "pdf") {
                let blob = new Blob([response.data], {
                  type: "application/pdf",
                });
                name += "-eSigns.pdf";
                let file = new File([blob], name, { type: blob.type });
                setSelectedFile(file);
                setUrl(window.URL.createObjectURL(new Blob([response.data])));

                setLoadPdfComplete(true);

                if (flowSelecting === "signed" || flowSelecting === "sent") {
                  setSignStatus("complete");
                }

                setIsLoading(false);
              } else if (item.fileExtension === "xml") {
                let reader = new FileReader();

                reader.onload = () => {
                  console.log("Xml :", reader.result);

                  let blob = new Blob([response.data], {
                    type: "text/xml",
                  });
                  name += "-eSigns.xml";
                  let file = new File([blob], name, { type: blob.type });
                  setSelectedFile(file);
                  setXml(reader.result);

                  setLoadXmlComplete(true);
                  //setExtension('xml');

                  if (flowSelecting === "signed" || flowSelecting === "sent") {
                    setSignStatus("complete");
                  }
                  setIsLoading(false);
                };

                reader.readAsText(new Blob([response.data]));
              }
            },
            (error) => {
              console.log(error);
            }
          );

        axios
          .get(API_URL + "/documents/" + item.key, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              console.log(response);

              setCertificateName(response.data.signatures[0].certificateName);
              setEmail(response.data.signatures[0].email);
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  /** @param {object} item {item.key: doc id} */
  const rejectFile = (item) => {
    let local_payload = payload;
    local_payload.id = item.key;
    let confirmed = window.confirm(t("Are you confirm to reject this file?"));
    if (confirmed) {
      setDefaultAlert(t("Reject file..."));
      RejectSign(local_payload).then((res) => {
        if (res === 200) {
          setSendSuccessAlert(t("Successful reject"));
        } else {
          setSendFailAlert(t("Fail to reject"));
        }
      });
    }
  };

  const deleteFile = (item) => {
    let local_payload = payload;
    local_payload.id = item.key;
    let confirmed = window.confirm(t("Are you confirm to delete this file?"));
    if (confirmed) {
      DeletePDF(local_payload);
    }
  };

  useEffect(() => {
    let local_payload = payload;
    if (
      document.getElementById("month-filter") &&
      document.getElementById("year-filter")
    ) {
      document.getElementById("month-filter").onchange = () => {
        setDocuments([]);
        setMonthSelecting(
          parseInt(document.getElementById("month-filter").value)
        );
      };
      document.getElementById("year-filter").onchange = () => {
        setDocuments([]);
        setYearSelecting(
          parseInt(document.getElementById("year-filter").value)
        );
      };
      local_payload.month = parseInt(monthSelecting);
      local_payload.year = parseInt(yearSelecting);
    }
    ListFilePDF(local_payload).then(
      (response) => {
        let alldocuments = [];
        let pend = { e: 0, d: 0 };
        if (response.data.length > 0) {
          for (let i = 0; i < response.data.length; i++) {
            if (response.data[i].type === "digital") {
              alldocuments.push({
                key: response.data[i].id,
                fileDisplayName: response.data[i].file.displayName,
                fileExtension: response.data[i].file.extension,
                fileLastModified: response.data[i].file.lastModified,
                fileSize: response.data[i].file.size,
                type: response.data[i].type,
                flow: response.data[i].flow,
                status: response.data[i].status,
                signature: response.data[i].signatures[0].certificateName
              });
              if (response.data[i].flow === "pending") {
                pend.d += 1;
              }
            } else if (
              response.data[i].type === "electronic" &&
              response.data[i].flow === "pending"
            ) {
              pend.e += 1;
            }
          }
          setPendingEsign(pend.e);
          setPendingDigitalsign(pend.d);
        }
        if(pend.e === 0) {
          setFlowSelecting("signed");
        }
        setDocuments(alldocuments);
        setLoadDataComplete(true);
      },
      (error) => {
        console.log(error);
      }
    );
  }, [loadDataComplete, dummyForUpdateList, monthSelecting, yearSelecting]);

  useEffect(() => {
    if (documents) {
      setPendingItems(getFlow("pending"));
      setSignedItems(getFlow("signed"));
      setSentItems(getFlow("sent"));
    }
  }, [documents]);

  useEffect(() => {
    function getFlowItems() {
      if (flowSelecting === "pending") {
        return pendingItems;
      } else if (flowSelecting === "signed") {
        return signedItems;
      } else if (flowSelecting === "sent") {
        return sentItems;
      } else return pendingItems;
    }
    let flowItems = getFlowItems();
    if (flowItems.length > 0) {
      const _docs = [];
      for (
        let i = (currentPage - 1) * pageSize;
        i < Math.min(currentPage * pageSize, flowItems.length);
        i++
      ) {
        _docs.push({
          key: flowItems[i].key,
          fileDisplayName: flowItems[i].fileDisplayName,
          fileExtension: flowItems[i].fileExtension,
          fileLastModified: flowItems[i].fileLastModified,
          fileSize: flowItems[i].fileSize,
          type: flowItems[i].type,
          flow: flowItems[i].flow,
          status: flowItems[i].status,
          signature: flowItems[i].signature,
        });
      }
      setItems(_docs);
    } else setItems([]);
  }, [currentPage, flowSelecting, pendingItems, signedItems, sentItems]);

  const getFlow = (flow) => {
    let dataFlow = [];
    documents.map((item) => {
      if (item.flow === flow) {
        dataFlow.push(item);
      }
    });
    return dataFlow;
  };

  useEffect(() => {}, [togglePdfa]);

  const columns = [
    {
      key: "column1",
      name: t("Name"),
      fieldName: "fileDisplayName",
      minWidth: 250,
      maxWidth: 250,
      isRowHeader: true,
      // isResizable: true,
      isSorted: true,
      isSortedDescending: false,
      sortAscendingAriaLabel: "Sorted A to Z",
      sortDescendingAriaLabel: "Sorted Z to A",
      onRender: (item) => {
        return (
          <span
            className="pointer-cursor columns-list-span"
            onClick={() => {
              openFile(item);
            }}
          >
            {item.fileDisplayName}
          </span>
        );
      },
      data: "string",
      isPadded: true,
    },
    {
      key: "column2",
      name: t("Signed Date"),
      fieldName: "fileLastModified",
      minWidth: 120,
      maxWidth: 120,
      // isResizable: true,
      isCollapsible: false,
      data: "number",
      onRender: (item) => {
        let fileModifiedTime = moment(item.fileLastModified);
        return (
          <span className="columns-list-span">
            {fileModifiedTime.format("DD/MM/YYYY  HH:mm:ss")}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: "column3",
      name: t("Signature"),
      fieldName: "certificateName",
      minWidth: 230,
      maxWidth: 230,
      isResizable: true,
      isCollapsible: false,
      data: "string",
      onRender: (item) => {
        return <span className="columns-list-span">{item.signature}</span>;
      },
      isPadded: true,
    },
    {
      key: "column4",
      name: t("File Size"),
      fieldName: "fileSize",
      minWidth: 80,
      maxWidth: 80,
      // isResizable: true,
      isCollapsible: false,
      data: "number",
      onRender: (item) => {
        return (
          <span className="columns-list-span">
            {formatBytes(item.fileSize)}
          </span>
        );
      },
      isPadded: true,
    },
    {
      key: "context",
      fieldName: "Context",
      minWidth: 120,
      maxWidth: 120,
      // isResizable: true,
      isCollapsible: false,
      data: "number",
      onRender: (item) => {
        return (
          <span className="columns-list-span">
            <DefaultButton
              text={t("Select")}
              menuProps={{
                items: [
                  {
                    key: "Download",
                    text: t("Download"),
                    iconProps: { iconName: "DownloadDocument" },
                    onClick: () => {
                      download(item);
                    },
                  },
                  {
                    key: "Open",
                    text: t("Open File"),
                    iconProps: { iconName: "OpenFile" },
                    onClick: () => {
                      openFile(item);
                    },
                  },
                ]
                  .concat(
                    flowSelecting === "pending"
                      ? [
                          {
                            key: "Reject",
                            text: t("Reject"),
                            iconProps: { iconName: "Cancel" },
                            onClick: () => {
                              rejectFile(item);
                              setDummyForUpdateList(!dummyForUpdateList);
                            },
                          },
                        ]
                      : []
                  )
                  .concat([
                    {
                      key: "Delete",
                      text: t("Delete File"),
                      iconProps: { iconName: "Delete" },
                      onClick: () => {
                        deleteFile(item);
                        setDummyForUpdateList(!dummyForUpdateList);
                      },
                    },
                    {
                      key: "Send",
                      text: t("Send File"),
                      iconProps: { iconName: "Send" },
                      onClick: () => {
                        setSendFile(item);
                      },
                    },
                  ]),
              }}
              styles={{
                root: {
                  fontSize: "16px",
                  padding: "5%",
                },
                menuIcon: { fontSize: "15px" },
              }}
            />
          </span>
        );
      },
      isPadded: true,
    },
  ];

  const GetShowingItems = () => {
    return (
      <div>
        <ShimmeredDetailsList
          items={items || []}
          enableShimmer={!loadDataComplete}
          compact={false}
          columns={columns}
          selectionMode={SelectionMode.none}
          layoutMode={DetailsListLayoutMode.justified}
          isHeaderVisible={true}
        />
        {!loadPdfComplete && !loadXmlComplete && !isLoading && (
          <Stack horizontalAlign="center">
            <br />
            {!(items.length === 0 || items.length < pageSize) ||
            currentPage !== 1 ? (
              <Pagination
                selectedPageIndex={currentPage - 1}
                pageCount={pageData.totalPages} //total pages
                onPageChange={onPageChange} //event when change page
                format
                firstPageIconProps={{ iconName: "DoubleChevronLeft" }}
                previousPageIconProps={{ iconName: "ChevronLeft" }}
                nextPageIconProps={{ iconName: "ChevronRight" }}
                lastPageIconProps={{ iconName: "DoubleChevronRight" }}
              />
            ) : null}
            <br />
            <br />
          </Stack>
        )}
      </div>
    );
  };

  return (
    <Stack className="containers-dsign">
      {sendFailAlert ? (
        <MessageBar
          actions={
            <div>
              <MessageBarButton
                onClick={() => {
                  setSendFailAlert(false);
                }}
              >
                {t("Dismiss")}
              </MessageBarButton>
            </div>
          }
          messageBarType={MessageBarType.error}
          isMultiline={false}
          styles={alertStyle}
        >
          {sendFailAlert}
        </MessageBar>
      ) : sendSuccessAlert ? (
        <MessageBar
          actions={
            <div>
              <MessageBarButton
                onClick={() => {
                  setSendSuccessAlert(false);
                }}
              >
                {t("Dismiss")}
              </MessageBarButton>
            </div>
          }
          messageBarType={MessageBarType.success}
          isMultiline={false}
          styles={alertStyle}
        >
          {sendSuccessAlert}
        </MessageBar>
      ) : defaultAlert ? (
        <MessageBar isMultiline={false} styles={alertStyle}>
          {defaultAlert}
        </MessageBar>
      ) : null}
      {sendFile ? (
        <SendWindow
          file={sendFile}
          setSendFile={setSendFile}
          setSendSuccessAlert={setSendSuccessAlert}
          setSendFailAlert={setSendFailAlert}
        />
      ) : (
        !loadPdfComplete &&
        !loadXmlComplete &&
        !isLoading && (
          <Stack>
            <Stack
              horizontal
              horizontalAlign="space-between"
              verticalAlign="end"
              style={{
                marginTop: "25px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Stack vertical style={{ width: "1000px" }}>
                <h1>
                  <i class="fas fa-file-signature"></i>
                  {t("Sign Documents (Digital Signature)")}
                </h1>
                <h4 style={{ color: "gray", marginTop: "5px" }}>
                  {t("Sign PDF or XML documents with digital signature")}
                </h4>
              </Stack>
            </Stack>
            <br />

            <Stack horizontal horizontalAlign="center">
              <div>
                <div style={{ width: 1000 }}>
                  <Container
                    {...getRootProps({
                      isDragActive,
                      isDragAccept,
                      isDragReject,
                    })}
                  >
                    <input {...getInputProps()} />
                    <br />
                    <br />
                    <div
                      style={{
                        fontSize: "80px",
                        color: "rgba(45, 45, 83, 0.808)",
                      }}
                    >
                      <i className="fas fa-files-medical"></i>
                    </div>
                    <button className="up-btn" onClick={open}>
                      {t("Choose File")}
                    </button>
                    <br />
                    <span className="dropzone-text">
                      {togglePdfa
                        ? t("Drag or Click here to sign PDF file")
                        : t("Drag or Click here to sign PDF or XML file")}
                    </span>
                  </Container>
                </div>
              </div>
            </Stack>
            <br />
            <Stack horizontal horizontalAlign="center">
              <Stack
                horizontal
                style={{
                  width: "98%",
                  maxWidth: "1000px",
                }}
              >
                <Pivot
                  overflowBehavior={"menu"}
                  styles={{ root: { width: "1000px" } }}
                  selectedKey={flowSelecting}
                  onLinkClick={(item) => {
                    let flow = item.props.itemKey;
                    if (flow === "pending") {
                      setPageData(
                        paginate(pendingItems.length, currentPage, pageSize, 10)
                      );
                    } else if (flow === "signed") {
                      setPageData(
                        paginate(signedItems.length, currentPage, pageSize, 10)
                      );
                    } else if (flow === "sent") {
                      setPageData(
                        paginate(sentItems.length, currentPage, pageSize, 10)
                      );
                    } else {
                      setPageData(false);
                    }
                    setFlowSelecting(flow);
                    setCurrentPage(1);
                  }}
                >
                  <PivotItem
                    headerText={t("Pending")}
                    itemKey="pending"
                    itemCount={pendingItems.length}
                    itemIcon="FieldRequired"
                  >
                    <GetShowingItems />
                  </PivotItem>

                  <PivotItem
                    headerText={t("Signed")}
                    itemIcon="PenWorkspace"
                    itemKey="signed"
                  >
                    <GetShowingItems />
                  </PivotItem>
                  <PivotItem
                    headerText={t("Sent")}
                    itemIcon="CheckMark"
                    itemKey="sent"
                  >
                    <GetShowingItems />
                  </PivotItem>
                </Pivot>
                <Stack horizontal className={classNames.filter}>
                  <div>
                    <p className="dropzone-filter-header">{t("Filter")}</p>
                  </div>
                  <div>
                    <label
                      htmlFor="month-filter"
                      className="dropzone-filter-text"
                    >
                      {t("Month")}
                    </label>
                    <select
                      id="month-filter"
                      name="month-filter"
                      className="dropzone-filter-dropdown"
                      defaultValue={monthSelecting}
                    >
                      {Array.from({ length: 12 }, (_, i) => i + 1).map(
                        (number) => {
                          /**find current month and selected */
                          return (
                            <option value={number} key={number.toString()}>
                              {i18n.language === "th"
                                ? new Date(number.toString()).toLocaleString(
                                    "th-TH",
                                    { month: "short" }
                                  )
                                : new Date(number.toString()).toLocaleString(
                                    "en-EN",
                                    { month: "short" }
                                  )}
                            </option>
                          );
                        }
                      )}
                    </select>
                    <label
                      htmlFor="year-filter"
                      className="dropzone-filter-text"
                    >
                      {t("Year")}
                    </label>
                    <select
                      id="year-filter"
                      name="year-filter"
                      className="dropzone-filter-dropdown"
                      defaultValue={yearSelecting}
                    >
                      {Array.from(
                        {
                          length:
                            (parseInt(moment().format("YYYY")) /**stop */ -
                              2021) /**start */ /
                              1 /**step */ +
                            1,
                        },
                        (_, i) => 2021 /**start */ + i * 1 /**step */
                      ).map((number) => {
                        let trans = i18n.language === "th" ? 543 : 0;
                        return (
                          <option value={number} key={number.toString()}>
                            {number + trans}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </Stack>
              </Stack>
            </Stack>

            <br />
          </Stack>
        )
      )}

      {(loadPdfComplete || loadXmlComplete) &&
        !(signStatus === "processing") &&
        !(signStatus === "complete") &&
        !(signStatus === "fail") && (
          <Stack>
            {/* <br/>   */}
            <Stack
              horizontal
              horizontalAlign="center"
              tokens={{ childrenGap: 50 }}
              style={{
                paddingTop: "15px",
                paddingBottom: "15px",
                background: "#4D4D4D",
                width: "calc(100vw - 250px)",
              }}
            >
              <Toggle
                label={<h2>PDF/A-3</h2>}
                inlineLabel
                onText={<h2>&nbsp;ON</h2>}
                offText={<h2>OFF</h2>}
                onChange={() => setTogglePdfa(!togglePdfa)}
                styles={{
                  root: { marginBottom: "0px" },
                  pill: { width: "70px", height: "25px", borderRadius: "0%" },
                  thumb: { width: "30px", height: "18px", borderRadius: "0%" },
                  label: { color: "white" },
                  text: { color: "white" },
                }}
              />
              <PrimaryButton
                text={t("Digital Sign")}
                onClick={sign}
                style={{ fontSize: "20px", height: "50px" }}
              ></PrimaryButton>
              <DefaultButton
                text={t("Cancel")}
                onClick={cancelSign}
                style={{ fontSize: "20px", height: "50px" }}
              ></DefaultButton>
              <div></div>
            </Stack>
            {/* <br/> */}

            <Stack horizontal horizontalAlign="center">
              <Stack
                vertical
                style={{
                  width: "calc(100vw - 250px)",
                }}
              >
                <div className="all-page-container">
                  {loadPdfComplete && <PdfAllPages pdf={url} />}
                  {loadXmlComplete && <XmlAllViewer xml={xml} />}
                </div>
              </Stack>
            </Stack>
          </Stack>
        )}

      {clickSign && (
        /* { true && */
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="loading-center">
              <Spinner
                /* size={SpinnerSize.large} */ label={t("Digital Signing...")}
                labelPosition="down"
                styles={spinnerStyles}
              />
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {isLoading && (
        /* { true && */
        <Stack>
          <Stack horizontal horizontalAlign="center">
            <br />
            <br />
            <div className="loading-center">
              <Spinner
                /* size={SpinnerSize.large} */ label={t("Processing...")}
                labelPosition="down"
                styles={spinnerStyles}
              />
            </div>
            <br />
          </Stack>
        </Stack>
      )}

      {(loadPdfComplete || loadXmlComplete) && signStatus === "complete" && (
        /* { false && */
        <Stack>
          {/* <br/>   */}
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 50 }}
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#4D4D4D",
              width: "calc(100vw - 250px)",
            }}
          >
            {/* <PrimaryButton text="เซ็นเอกสาร" onClick={signPdf}></PrimaryButton>   */}
            <DefaultButton
              text={t("Download")}
              onClick={downloadSign}
              style={{ fontSize: "20px", height: "50px", width: "120px" }}
            ></DefaultButton>
            <DefaultButton
              text={t("Back")}
              onClick={cancelSign}
              style={{ fontSize: "20px", height: "50px", width: "120px" }}
            ></DefaultButton>
          </Stack>

          {certificateName && (
            <Stack
              horizontal
              horizontalAlign="center"
              style={{
                paddingTop: "5px",
                paddingBottom: "5px",
                background: "#C7E3F8",
                width: "calc(100vw - 250px)",
              }}
            >
              <FontIcon iconName="PenWorkspace" className={checkIconClass} />
              &nbsp;
              <FontIcon
                iconName="SkypeCircleCheck"
                className={checkIconClass}
              />
              <span style={{ marginLeft: "8px" }}>
                {t("Digital signed by")} {certificateName} &#60;{email}&#62;
              </span>
            </Stack>
          )}
          {/* <br/> */}

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: "calc(100vw - 250px)",
                height: "100%",
                //minWidth: '1070px',
                //maxWidth: '1200px'
              }}
            >
              <div className="all-page-container">
                {loadPdfComplete && <PdfAllPages pdf={url} />}
                {loadXmlComplete && <XmlAllViewer xml={xml} />}
              </div>
            </Stack>
          </Stack>
        </Stack>
      )}

      {(loadPdfComplete || loadXmlComplete) && signStatus === "fail" && (
        /* { true && */
        <Stack>
          {/* <br/>   */}
          <Stack
            horizontal
            horizontalAlign="center"
            tokens={{ childrenGap: 50 }}
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
              background: "#4D4D4D",
            }}
          >
            {/* <PrimaryButton text="เซ็นเอกสาร" onClick={signPdf}></PrimaryButton>   */}
            <DefaultButton text="กลับ" onClick={cancelSign}></DefaultButton>
          </Stack>
          <Stack
            horizontal
            horizontalAlign="center"
            style={{
              paddingTop: "5px",
              paddingBottom: "5px",
              background: "#C7E3F8",
            }}
          >
            <FontIcon iconName="StatusErrorFull" className={errorIconClass} />
            <span style={{ marginLeft: "8px" }}>
              Digital signed fail. Please try again...
            </span>
          </Stack>
          {/* <br/> */}

          <Stack horizontal horizontalAlign="center">
            <Stack
              vertical
              style={{
                width: "calc(100vw - 250px)",
                height: "100%",
                //minWidth: '1070px',
                //maxWidth: '1200px'
              }}
            >
              <div className="all-page-container">
                {loadPdfComplete && <PdfAllPages pdf={url} />}
                {loadXmlComplete && <XmlAllViewer xml={xml} />}
              </div>
            </Stack>
          </Stack>
        </Stack>
      )}

      {/* {!loadPdfComplete && !loadXmlComplete && !isLoading && (
        <Stack horizontalAlign="center">
          <br />
          {!(documents.length === 0 || documents.length < pageSize) && (
            <Pagination
              selectedPageIndex={currentPage - 1}
              pageCount={pageData.totalPages}
              onPageChange={onPageChange}
              format
              firstPageIconProps={{ iconName: "DoubleChevronLeft" }}
              previousPageIconProps={{ iconName: "ChevronLeft" }}
              nextPageIconProps={{ iconName: "ChevronRight" }}
              lastPageIconProps={{ iconName: "DoubleChevronRight" }}
            />
          )}
          <br />
          <br />
        </Stack>
      )} */}

      {/*
            <br/>
            <br/>
            <br/>
            <br/>
            <Document 
                file={testPdf} 
                //options={{ workerSrc: "/pdf.worker.js" }}
               
                //onLoadError={console.error}
            >
                <Page pageNumber={1} />
            </Document> 

            
            <div className="loading-center">
                <Spinner label="กำลังลงลายเซ็นดิจิทัล..." labelPosition="down" styles={spinnerStyles} />
            </div>
            */}
    </Stack>
  );
};

export default DigitalSignature;
