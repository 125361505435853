import React, { useState } from "react";
import { useMsal, useAccount } from "@azure/msal-react";
import {
  silentRequest,
  profileEditingRequest,
  passwordResetRequest,
} from "../../../authConfig";

import axios from "axios";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontIcon } from "@fluentui/react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";

const API_URL = process.env.REACT_APP_API_URL;

const FirstPage = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [email, setEmail] = useState("");
  const { t } = useTranslation();
  initializeIcons();

  const logOut = () => {
    instance.logout();
  };

  const editProfile = () => {
    instance.loginRedirect(profileEditingRequest);
  };

  const resetPassword = () => {
    instance.loginRedirect(passwordResetRequest);
  };

  const getToken = () => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log("Access token: ", tokenResponse.accessToken);
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  const getUserProfile = () => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        //console.log('Access token: ', tokenResponse.accessToken);
        //console.log('Token: ', tokenResponse);

        axios
          .get(API_URL + "/accounts", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            },
          })
          .then(
            (response) => {
              //console.log(response);
              if (response.data) {
                //console.log(JSON.stringify(response.data));
                console.log("User profile: ", response.data);
                setEmail(response.data.profiles.email);
              }
            },
            (error) => {
              console.log(error);
            }
          );
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };

  return (
    <div>
      <Link exact to="/electronic-signatures" className="sidebar-show">
        <a>
          <i className="fas fa-home"></i> {t("Home")}{" "}
        </a>
      </Link>

      <Link exact to="/certificates" className="sidebar-show">
        <a>
          <FontIcon iconName="Telemarketer" />
          {t("My Digital Signature")}{" "}
        </a>
      </Link>

      <Link exact to="/contact-us" className="sidebar-show">
        <a>
          <i class="far fa-address-card"></i>
          {t("Contact Us")}{" "}
        </a>
      </Link>

      <li onClick={editProfile} className="nav-link">
        <i class="fas fa-user-circle"></i> {t("Edit profile")}
      </li>

      <li onClick={resetPassword} className="nav-link">
        <i class="fas fa-unlock-alt"></i> {t("Reset password")}
      </li>

      <li onClick={logOut} className="nav-link">
        <i class="fas fa-sign-out-alt"></i> {t("Log out")}
      </li>
    </div>
  );
};

export default FirstPage;
