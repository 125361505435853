import axios from "axios";
import { silentRequest } from "../authConfig";

/**
 * @function UploadPDF upload PDF
 * @param {Object} payload {instance, account, selectedFile, name}
 * @returns {Promise} data
 */
export const UploadPDF = (payload) => {
  return new Promise((resolve, reject) => {
    payload.instance
    .acquireTokenSilent({ ...silentRequest, account: payload.account })
    .then((tokenResponse) => {
      let promises = [];
      let formData = new FormData();

      formData.append("file", payload.selectedFile);
      formData.append("fileName", payload.name);

      promises.push(
        axios.post("https://ws.esigns.cloud/documents/pdf/electronicsign", formData, {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
            "Content-Type": "multipart/form-data",
          },
        })
      );

      Promise.all(promises).then(function (results) {
        resolve(results[0].data);
      });
    })
    .catch((error) => {
      console.log(error);
      payload.instance.acquireTokenRedirect(silentRequest);
      reject();
    });
  })
  
}
