import { useState, useEffect } from 'react';

function getSessionStorageOrDefault(key, defaultValue) {
    const stored = sessionStorage.getItem(key);
    if (!stored) {
      sessionStorage.setItem(key, JSON.stringify(defaultValue));
      return defaultValue;
    }
    return JSON.parse(stored);
  }
  
  /**
   * @param {string} key key of sessionStorage
   * @param {any} defaultValue [default: "0"] default value of sessionStorage, will convert to string later
   * @returns {Array} [sessionStorage.getItem, sessionStorage.setItem]
   */
  export function useSessionStorage(key, defaultValue = "0") {
    const [value, setValue] = useState(
      getSessionStorageOrDefault(key, defaultValue)
    );
  
    useEffect(() => {
      sessionStorage.setItem(key, JSON.stringify(value));
    }, [key, value]);
  
    return [value, setValue];
  }