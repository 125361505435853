import axios from "axios";
import { silentRequest } from "../authConfig";

/**
 * @function DeleteFile delete user's signature
 * @param {Object} payload {instance, account}
 */
export const DeleteFile = (payload) => {
  payload.instance
    .acquireTokenSilent({ ...silentRequest, account: payload.account })
    .then((tokenResponse) => {
      axios.delete("https://ws.esigns.cloud/signatures", {
        headers: {
          Authorization: "Bearer " + tokenResponse.accessToken,
        },
      });
    })
    .catch((error) => {
      console.log(error);
      payload.instance.acquireTokenRedirect(silentRequest);
    });
};
