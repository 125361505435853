import axios from "axios";
import { silentRequest } from "../authConfig";

/**
 * @function DeleteStamp delete user's stamp
 * @param {Object} payload {instance, account}
 * @returns {string} string of delete status
 */
export const DeleteStamp = (payload) => {
  return payload.instance
    .acquireTokenSilent({ ...silentRequest, account: payload.account })
    .then((tokenResponse) => {
        return axios
        .delete("https://ws.esigns.cloud/stamps/", {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }).then((res) => {
          return res.status;
        }).catch((error) => {
          return error;
        })
    })
    .catch((error) => {
      payload.instance.acquireTokenRedirect(silentRequest);
      return error;
    });
};