import axios from "axios";
import { silentRequest } from "../authConfig";

/**
 * @function RejectSign reject to sign
 * @param {Object} payload {instance, account, id}
 * @returns status
 */
export const RejectSign = (payload) => {
  return payload.instance
    .acquireTokenSilent({ ...silentRequest, account: payload.account })
    .then((tokenResponse) => {
        return axios
        .patch("https://ws.esigns.cloud/documents/reject/" + payload.id, {}, {
          headers: {
            Authorization: "Bearer " + tokenResponse.accessToken,
          },
        }).then((res) => {
          return res.status;
        }).catch((error) => {
          return error;
        })
    })
    .catch((error) => {
      payload.instance.acquireTokenRedirect(silentRequest);
      return error;
    });
};
