// Config object to be passed to Msal on creation
export const msalConfig = {

    auth: {
        clientId: process.env.REACT_APP_B2C_CLIENT_ID,
        authority: process.env.REACT_APP_B2C_LOGIN_AUTHORITY,        
        knownAuthorities: [process.env.REACT_APP_B2C_KNOWN_AUTHORITIES],
        redirectUri: process.env.REACT_APP_B2C_REDIRECT_URI,
        postLogoutRedirectUri: process.env.REACT_APP_B2C_POST_LOGOUT_REDIRECT_URI,
        // redirectUri: "http://localhost:3000",
        // postLogoutRedirectUri: "http://localhost:3000",
        //validateAuthority: false
    },
    
    cache: {
        cacheLocation: "sessionStorage"
        //storeAuthStateInCookie: true,
    }
    
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["https://esignatures.onmicrosoft.com/api/user_impersonation"]
    //scopes: [process.env.REACT_APP_B2C_LOGIN_SCOPES]
    
};

export const silentRequest = {
    scopes: ["https://esignatures.onmicrosoft.com/api/user_impersonation"]
    //scopes: [process.env.REACT_APP_B2C_SILENT_SCOPES]

}

export const passwordResetRequest = {
    
    authority: process.env.REACT_APP_B2C_PASSWORD_AUTHORITY
}

export const profileEditingRequest = {
    
    authority: process.env.REACT_APP_B2C_PROFILE_AUTHORITY
}

