import React, { useState, useEffect, useRef, useCallback } from "react";
import {
  silentRequest,
  profileEditingRequest,
  passwordResetRequest,
} from "../../../authConfig";
import axios from "axios";
import { useMsal, useAccount } from "@azure/msal-react";
import { useDropzone } from "react-dropzone";
import "./digitalsign.css";
import ReactNotification, { store } from "react-notifications-component";
import { useHistory } from "react-router-dom";
import { BounceLoader, BarLoader, BeatLoader } from "react-spinners";

var forge = require("node-forge");

const DigitalSigns = () => {
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [isloading, setLoad] = useState(false);
  const [openModal, setModal] = useState(false);
  let history = useHistory();
  const Message = () => {
    store.addNotification({
      title: "Success",
      message: "Upload .pfx/.p12 file success !!",
      type: "success",
      container: "bottom-full",
      insert: "top",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],

      dismiss: {
        duration: 5000,
      },
    });
  };

  const MessageFail = () => {
    store.addNotification({
      title: "Error",
      message: "Upload Fail !!",
      type: "danger",
      container: "bottom-full",
      insert: "top",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],

      dismiss: {
        duration: 5000,
      },
    });
  };
  const CantUpload = () => {
    store.addNotification({
      title: "Error",
      message: "Please choose file after upload",
      type: "danger",
      container: "bottom-full",
      insert: "top",
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],

      dismiss: {
        duration: 5000,
      },
    });
  };

  const [pwd, setPwd] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [status, setStatus] = useState(
    "Please select file and input password..."
  );
  const [filename, setname] = useState("No file Choose");

  const onDrop = useCallback((files) => {
    // Do something with the files
    console.log(files);
    setname(files.path);
    var reader = new FileReader();
    reader.onload = function (e) {
      var fileContent = e.target.result;
      if (fileContent !== undefined) {
        setModal(true);
      }
      // decrypt p12 using the password returned by getPassword(), the password should be protected and not hard coded
      try {
        // get p12 as ASN.1 object
        var p12Asn1 = forge.asn1.fromDer(fileContent);

        var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, "leceiptleceipt");

        // get bags by type
        var certBags = p12.getBags({ bagType: forge.pki.oids.certBag });
        var pkeyBags = p12.getBags({
          bagType: forge.pki.oids.pkcs8ShroudedKeyBag,
        });
        // fetching certBag
        var certBag = certBags[forge.pki.oids.certBag][0];
        // fetching keyBag
        var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];

        if (certBag && keybag) {
          console.log("on Drop cerBag :", certBag.cert);
          console.log("Success then upload file!");
          setLoad(true);
          setModal(false);
        }
      } catch (error) {
        console.log(error);
        console.log("Fail!");
      }
    };
    reader.readAsBinaryString(files[0]);
  }, []);

  const { acceptfile, getRootProps, getInputProps } = useDropzone({ onDrop });

  const uploadFile = () => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        console.log("Access token: ", tokenResponse.accessToken);
        console.log("Token: ", tokenResponse);

        let promises = [];

        //Encode base64
        let buff = Buffer.from(pwd);
        let base64pwd = buff.toString("base64");

        //files.forEach(file => {

        //console.log(file.path);
        let formData = new FormData();
        //formData.append('requestId', requestId);
        formData.append("file", selectedFile);
        formData.append("password", base64pwd);
        //console.log(formData);

        promises.push(
          axios.post("https://ws.esigns.cloud/certificates", formData, {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
              "Content-Type": "multipart/form-data",
              //'OID': 'b5cf38c9-5e52-4bd3-b737-caf5c6d30635'
              //'api-key': '6c8921222ff946479e523edeca961f51'
            },
          })
        );

        //});

        Promise.all(promises).then(function (results) {
          results.forEach(function (response) {
            console.log("Response upload: ", response.data);
            setLoad(false);
            setStatus("Upload .pfx/.p12 file success!");
            Message();
            setModal(false);

            setTimeout(() => {
              reDirect();
            }, 3000);
          });
        });
      })
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        instance.acquireTokenRedirect(silentRequest);
      });
  };
  const reDirect = () => {
    history.push("/MainDigital");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Do something with the files
    console.log("SelectedFile: ", selectedFile);
    //const reader = new FileReader();
    var reader = new FileReader();
    reader.onload = function (e) {
      var fileContent = e.target.result;
      setname(e.path);

      // decrypt p12 using the password returned by getPassword(), the password should be protected and not hard coded
      try {
        // get p12 as ASN.1 object
        var p12Asn1 = forge.asn1.fromDer(fileContent);

        var p12 = forge.pkcs12.pkcs12FromAsn1(p12Asn1, pwd);

        // get bags by type
        var certBags = p12.getBags({ bagType: forge.pki.oids.certBag });
        var pkeyBags = p12.getBags({
          bagType: forge.pki.oids.pkcs8ShroudedKeyBag,
        });
        // fetching certBag
        var certBag = certBags[forge.pki.oids.certBag][0];
        // fetching keyBag
        var keybag = pkeyBags[forge.pki.oids.pkcs8ShroudedKeyBag][0];
        // generate pem from private key
        //var privateKeyPem = forge.pki.privateKeyToPem(keybag.key);
        // generate pem from cert
        //var certificate = forge.pki.certificateToPem(certBag.cert);

        if (certBag && keybag) {
          //console.log(certBag.cert);
          console.log("Success then upload file!");
          setStatus("Loading..");
          setLoad(true);
          uploadFile();
        }
      } catch (error) {
        console.log(error);
        console.log("Fail!");
        setStatus("Fail!");

        MessageFail();
      }
    };
    if (selectedFile === null) {
      CantUpload();
    } else {
      reader.readAsBinaryString(selectedFile);
    }
  };

  return (
    <div>
      <div className="Header">
        <h1>
          <i class="fad fa-file-certificate"></i>New Certificate
        </h1>
        <h4>create new certificate</h4>
      </div>

      <ReactNotification />
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="Drop">
            <div {...getRootProps({ className: "xlm" })}>
              <label For="fileElem">
                {" "}
                <i class="fas fa-pen-nib"></i>Drop or Click your file here{" "}
              </label>

              <input
                type="file"
                accept=".pfx, .p12, application/x-pkcs12"
                id="fileElem"
                onChange={(ondrop) => {
                  setSelectedFile(ondrop.target.files[0]);
                }}
              />
            </div>
          </div>

          <div className="password">
            <label for="pwd" id="pwd" name="pwd">
              Password for open file:{" "}
            </label>
            <input
              type="password"
              value={pwd}
              onChange={(e) => {
                setPwd(e.target.value);
                setStatus("");
              }}
            />

            <div> {isloading ? <BeatLoader color="blue" loading /> : ""}</div>
            <button type="submit">Upload file</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default DigitalSigns;
