import axios from "axios";
import { silentRequest } from "../authConfig";

/**
 * @function DownloadFile download signature
 * @param {Object} payload {instance, account}
 * @returns data || reject if no file
 */
export const DownloadFile = (payload) => {
  return new Promise((resolve, reject) => {
    payload.instance
      .acquireTokenSilent({ ...silentRequest, account: payload.account })
      .then((tokenResponse) => {
        axios
          .get("https://ws.esigns.cloud/signatures", {
            headers: {
              Authorization: "Bearer " + tokenResponse.accessToken,
            }
          })
          .then(
            (res) => {
              resolve(res.data);
            },
            () => {
              reject();
            }
          );
      }).catch(() => {
      payload.instance.acquireTokenRedirect(silentRequest);
      reject();
    });
  });
};
