import React from 'react';
import XMLViewer from 'react-xml-viewer';


export default function XmlAllViewer(props) {
  
    const { xml } = props;  

    return (
        <div style={{display: 'flex', justifyContent: 'center'}}>
            <div style={{ backgroundColor: 'white', width: '950px', padding: '25px' }}>
            {/* {formattedXml} */}            
            <XMLViewer xml={xml} collapsible={true} theme={{ "overflowBreak": true }} />
        </div>
        </div>
        
    );
}