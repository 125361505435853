import React from 'react';
import XMLViewer from 'react-xml-viewer';

export default function XmlAllViewer(props) {
  
    const { xml } = props;  

    return (
        <div style={{ backgroundColor: 'white', width: '100vw', padding: '25px' }}>
            {/* {formattedXml} */}            
            <XMLViewer xml={xml} collapsible={true} theme={{ "overflowBreak": true }} />
            
        </div>
    );
}