import { React, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useMsal, useAccount } from "@azure/msal-react";
import { silentRequest } from "../../../authConfig";
import { FontIcon, PrimaryButton, DefaultButton, MessageBar, MessageBarButton, MessageBarType } from "@fluentui/react";
import { initializeIcons } from "@fluentui/font-icons-mdl2";
import { mergeStyles } from "@fluentui/react/lib/Styling";
import moment from "moment";
import "./css/Form.css";

import { SendFile } from "../../../api_function/sendFile";
import { useSessionStorage } from "../../../session_storage/Session"
import { useLocalStorage } from "../../../local_storage/LocalStorage";

export const SendWindow = (props) => {
  const { t } = useTranslation();
  const { instance, accounts } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [name, setName] = useState("");
  const [email, setEmail] = useState(""); //sender email
  const [receiver, setReceiver] = useState(""); //receiver email
  const [message, setMessage] = useState("");
  const [statusText, setStatusText] = useState("");
  const [sending, setSending] = useState(false);
  const [sendFailAlert, setSendFailAlert] = useState(false);

  const [, setFlowSelecting] = useSessionStorage("flowSelecting", "signed");
  const [, setMonthSelecting] = useLocalStorage(
    "monthSelecting",
    parseInt(moment().format("MM"))
  );
  const [, setYearSelecting] = useLocalStorage(
    "yearSelecting",
    parseInt(moment().format("YYYY"))
  );

  initializeIcons();
  const iconClass = mergeStyles({
    fontSize: 50,
  });
  const iconxxClass = mergeStyles({
    fontSize: "xx-large",
  });

  const buttonStyle = {
    root: {
      fontSize: "xx-large",
      padding: "5%",
      width: "45%"
    },
  };
  const alertStyle = {
    root: {
      position: "fixed",
      top: "60px",
      left: "250px",
      width: "calc(100vw - 250px)",
      height: "5vh",
      zIndex: 10
    },
    iconContainer: {
      alignItems: "center"
    },
    text: {
      alignItems: "center"
    },
    innerText: {
      fontSize: "larger"
    }
  }

  useEffect(() => {
    instance
      .acquireTokenSilent({ ...silentRequest, account: account })
      .then((tokenResponse) => {
        setName(
          tokenResponse.account.idTokenClaims.given_name +
            " " +
            tokenResponse.account.idTokenClaims.family_name
        );
        setEmail(tokenResponse.account.idTokenClaims.emails[0]);
      });
    let status = props.file.status;
    let flow = props.file.flow;
    if (flow === "pending") {
      if (status === "received") {
        setStatusText(t("Recieved on"));
      } else if (status === "opened") {
        setStatusText(t("Opened on"));
      }
    } else if (flow === "signed") {
      setStatusText(t("Signed on"));
    } else if (flow === "sent") {
      if (status === "sent") {
        setStatusText(t("Sent on"));
      } else if (status === "opened") {
        setStatusText(t("Opened on"));
      }
    } else {
      setStatusText(t("Rejected on"));
    }

    document.getElementById("back").onclick = cancelSign;
    document.getElementById("send").addEventListener("click", submitForm);
    document.getElementById("cancel").addEventListener("click", cancelSign);

    function submitForm() {
      setSending(true);
    }
    function cancelSign() {
      props.setSendFile(false);
    }
  }, []);

  useEffect(() => {
    if (sending) {
      if (validate()[0] && validate()[1]) {
        let payload = {
          instance: instance,
          account: account,
          id: props.file.key,
          email: email,
          message: message,
          receiver: receiver,
          name: name,
        };
        SendFile(payload).then((res) => {
          if (res.status === 200) {
            props.setSendSuccessAlert(t("Send Success"));
          } else {
            props.setSendFailAlert(t("Something went wrong, please try again"));
          }
          setSending(false);
          setFlowSelecting("signed");
          setMonthSelecting(moment().format("MM"));
          setYearSelecting(moment().format("YYYY"));
          props.setSendFile(false);
        });
      } else {
        setSending(false);
      }
    }
  }, [sending]);

  const validate = () => {
    let mail =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    let validateEmail = mail.test(receiver);
    if (!validateEmail || !message) {
      setSendFailAlert(t("Invalid input"));
      if (!validateEmail) {
        document.getElementById("to").style.borderColor = "crimson";
      } else {
        document.getElementById("to").style.borderColor = "black";
      }
      if (!message) {
        document.getElementById("message").style.borderColor = "crimson";
      } else {
        document.getElementById("message").style.borderColor = "black";
      }
    }
    return [validateEmail ? true : false, message ? true : false];
  };

  const DocumentIcon = () => {
    let type = props.file.type;
    if (type === "electronic") {
      return (
        <FontIcon
          aria-label="EditMirrored"
          iconName="EditMirrored"
          className={iconClass}
        />
      );
    } else if (type === "digital") {
      return (
        <FontIcon
          aria-label="Certificate"
          iconName="Certificate"
          className={iconClass}
        />
      );
    } else
      return (
        <FontIcon
          aria-label="StatusCircleQuestionMark"
          iconName="StatusCircleQuestionMark"
          className={iconClass}
        />
      );
  };

  return (
    <div class="forms">
      {
        sendFailAlert ? (
          <MessageBar
          actions={
            <div>
              <MessageBarButton onClick={() => {setSendFailAlert(false)}}>{t("Dismiss")}</MessageBarButton>
            </div>
          }
          messageBarType={MessageBarType.error}
          isMultiline={false}
          styles={alertStyle}
        >
          {sendFailAlert}
        </MessageBar>
        ) : null
      }

      <h1>
        <FontIcon
          aria-label="Back"
          iconName="Back"
          className={iconxxClass}
          id="back"
        />{" "}
        {t("Send File")}
      </h1>
      <div class="divider" />
      <div>
        <label for="from" className="form-label">{t("From")}:</label>
        <input type="email" name="from" disabled={true} value={email} />
      </div>
      <br />
      <div>
        <label for="to" className="form-label">{t("To")}:</label>
        <input
          type="email"
          name="to"
          id="to"
          value={receiver}
          onChange={(event) => setReceiver(event.target.value)}
        />
      </div>
      <div class="divider" />
      <div class="frame">
        <div class="icon">
          <DocumentIcon />
        </div>
        <div>
          <h3>{props.file.fileDisplayName || "Untitled"}</h3>
          <p>
            {statusText +
              " " +
              moment(props.file.fileLastModified).format("DD/MM/YYYY  HH:mm")}
          </p>
        </div>
      </div>
      <div class="divider" />
      <div>
        <label className="form-label">{t("Message")}:</label>
        <br />
        <textarea
          name="message"
          id="message"
          rows="5"
          value={message}
          onChange={(event) => setMessage(event.target.value)}
        />
      </div>
      <div className="submit-buttons-frame">
        {sending ? (
          <PrimaryButton
            type="button"
            className="submit-button"
            styles={buttonStyle}
            disabled={true}
          >
            {t("Sending...")}
          </PrimaryButton>
        ) : (
          <PrimaryButton
            type="button"
            className="submit-button"
            styles={buttonStyle}
            id="send"
          >
            {t("Submit File")}
          </PrimaryButton>
        )}
        <DefaultButton
          type="button"
          className="submit-button"
          styles={buttonStyle}
          id="cancel"
          disabled={sending}
        >
          {t("Cancel")}
        </DefaultButton>
      </div>
    </div>
  );
};
