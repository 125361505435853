import React, { useState, useEffect } from "react";
import { initializeIcons, getTheme, Icon, Stack } from "@fluentui/react";
import { Sidebar, SidebarButton } from "@uifabric/experiments";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./FluentSidebar.css";
import { useSessionStorage } from "../../../session_storage/Session";

const FluentSidebar = () => {
  initializeIcons();

  const { t } = useTranslation();

  const history = useHistory();

  const [signsDocsClick, setSignDocsClick] = useState(true);
  const [settingClick, setSettingClick] = useState(false);

  const [esignClick, setEsignClick] = useState(false);
  const [dsignClick, setDsignClick] = useState(false);
  const [certClick, setCertClick] = useState(false);
  const [contactClick, setContactClick] = useState(false);

  const [dummyForUpdate, setDummyForUpdate] = useState(false);

  const [pendingEsign, setPendingEsign] = useSessionStorage("pendingEsign", 0);
  const [pendingDigitalsign, setPendingDigitalsign] = useSessionStorage(
    "pendingDigitalsign",
    0
  );
  useEffect(() => {
    if (
      pendingEsign !== JSON.parse(sessionStorage.getItem("pendingEsign")) &&
      sessionStorage.getItem("pendingEsign")
    ) {
      setPendingEsign(JSON.parse(sessionStorage.getItem("pendingEsign")));
    }
    if (
      pendingDigitalsign !==
        JSON.parse(sessionStorage.getItem("pendingDigitalsign")) &&
      sessionStorage.getItem("pendingDigitalsign")
    ) {
      setPendingDigitalsign(
        JSON.parse(sessionStorage.getItem("pendingDigitalsign"))
      );
    }
  }, [pendingDigitalsign, pendingEsign, dummyForUpdate]);

  useEffect(() => {
    window.onclick = () => {
      setDummyForUpdate(!dummyForUpdate);
    };
  }, [dummyForUpdate]);

  useEffect(() => {
    let urlPath = window.location.pathname;

    if (urlPath === "/") {
      setEsignClick(true);
    } else if (urlPath === "/electronic-signatures") {
      setEsignClick(true);
    } else if (urlPath === "/digital-signatures") {
      setDsignClick(true);
    } else if (urlPath === "/certificates") {
      setCertClick(true);
    } else if (urlPath === "/contact-us") {
      setContactClick(true);
    }
  });

  return (
    <div>
      <Sidebar
        collapsible={false}
        theme={getTheme()}
        collapseButtonAriaLabel={"sitemap"}
        styles={{
          root: {
            height: "100%",
            minHeight: "calc(100vh - 60px)",
            width: "250px",
            position: "sticky",
            overflow: "hidden",
          },
        }}
        items={[
          {
            key: "item1",
            onRender: () => {
              return (
                <Stack
                  horizontal
                  className="sidebar-header-container"
                  onClick={() => {
                    setSignDocsClick(!signsDocsClick);
                  }}
                >
                  <Stack horizontal>
                    <Icon
                      iconName="PenWorkspace"
                      className="sidebar-dropdown-icon"
                    />
                    <span className="sidebar-header-text">
                      {t("Sign Docs")}
                    </span>
                  </Stack>

                  <Icon
                    iconName={signsDocsClick ? "ChevronUp" : "ChevronDown"}
                    className="sidebar-dropdown-icon"
                  />
                </Stack>
              );
            },
          },
        ]
          .concat(
            signsDocsClick
              ? [
                  {
                    name: "ลายเซ็นอิเล็กทรอนิกส์",
                    key: "/electronic-signatures",
                    url: "/electronic-signatures",
                    iconProps: {
                      iconName: "InsertSignatureLine",
                      styles: {
                        root: {
                          fontSize: "26px",
                          color: "rgb(253, 253, 253)",
                        },
                      },
                    },
                    active: true,
                    onRender: (item) => {
                      return (
                        <SidebarButton
                          key={item.key}
                          url={item.url}
                          iconProps={item.iconProps}
                          role="menuitem"
                          theme={getTheme()}
                          checked={esignClick}
                          onClick={() => {
                            setEsignClick(true);
                            setDsignClick(false);
                            setCertClick(false);
                            setContactClick(false);
                            history.push(item.url);
                          }}
                          style={{ paddingLeft: "3%" }}
                          // onMouseOver={(e) => {
                          //   if(e.currentTarget.tabIndex === 0){

                          //     document.querySelector(".noti-e").style.left = "21px";
                          //   }
                          // }}
                          // onMouseLeave={() => {
                          //   document.querySelector(".noti-e").style.left = "25px";
                          // }}
                          styles={{
                            root: {
                              backgroundColor: "#3A3A3A",
                              height: "100px",
                            },
                            rootHovered: {
                              backgroundColor: "#074f97",
                            },
                            rootPressed: {
                              backgroundColor: "#05386B",
                            },
                            rootFocused: {
                              backgroundColor: "#05386B",
                            },
                            rootChecked: {
                              backgroundColor: "#05386B",
                            },
                            rootCheckedHovered: {
                              backgroundColor: "#05386B",
                            },
                            rootCheckedPressed: {
                              backgroundColor: "#05386B",
                            },
                          }}
                        >
                          {pendingEsign !== 0 ? (
                            <span className="sidebar-notification noti-e">
                              {pendingEsign}
                            </span>
                          ) : (
                            ""
                          )}
                          <label className="sidebar-label">
                            {t("Electronic Signature") + " "}
                          </label>
                        </SidebarButton>
                      );
                    },
                  },
                  {
                    name: "ลายเซ็นดิจิทัล",
                    key: "/digital-signatures",
                    url: "/digital-signatures",
                    iconProps: {
                      iconName: "InsertSignatureLine",
                      styles: {
                        root: {
                          fontSize: "26px",
                          color: "rgb(253, 253, 253)",
                        },
                      },
                    },
                    active: true,
                    onRender: (item) => {
                      return (
                        <div>
                          <SidebarButton
                            key={item.key}
                            url={item.url}
                            iconProps={item.iconProps}
                            role="menuitem"
                            theme={getTheme()}
                            checked={dsignClick}
                            onClick={() => {
                              setEsignClick(false);
                              setDsignClick(true);
                              setCertClick(false);
                              setContactClick(false);
                              history.push(item.url);
                            }}
                            style={{ paddingLeft: "3%" }}
                            // onMouseOver={(e) => {
                            //   if(e.currentTarget.tabIndex === 0){
                            //     document.querySelector(".noti-d").style.left = "21px";
                            //   }
                            // }}
                            // onMouseLeave={() => {
                            //   document.querySelector(".noti-d").style.left = "25px";
                            // }}
                            styles={{
                              root: {
                                backgroundColor: "#3A3A3A",
                                height: "100px",
                              },
                              rootHovered: {
                                backgroundColor: "#074f97",
                              },
                              rootPressed: {
                                backgroundColor: "#05386B",
                              },
                              rootFocused: {
                                backgroundColor: "#05386B",
                              },
                              rootChecked: {
                                backgroundColor: "#05386B",
                              },
                              rootCheckedHovered: {
                                backgroundColor: "#05386B",
                              },
                              rootCheckedPressed: {
                                backgroundColor: "#05386B",
                              },
                            }}
                          >
                            {pendingDigitalsign !== 0 ? (
                              <span className="sidebar-notification noti-d">
                                {pendingDigitalsign}
                              </span>
                            ) : (
                              ""
                            )}
                            <label className="sidebar-label">
                              {t("Digital Signature") + " "}
                            </label>
                          </SidebarButton>
                        </div>
                      );
                    },
                  },
                ]
              : []
          )
          .concat([
            {
              key: "item2",
              onRender: () => {
                return (
                  <Stack
                    horizontal
                    className="sidebar-header-container"
                    onClick={() => {
                      setSettingClick(!settingClick);
                    }}
                  >
                    <Stack horizontal>
                      <Icon
                        iconName="ContactCardSettings"
                        className="sidebar-dropdown-icon"
                      />
                      <span className="sidebar-header-text">
                        {t("Settings")}
                      </span>
                    </Stack>
                    <Icon
                      iconName={settingClick ? "ChevronUp" : "ChevronDown"}
                      className="sidebar-dropdown-icon"
                    />
                  </Stack>
                );
              },
            },
          ])
          .concat(
            settingClick
              ? [
                  {
                    name: "ใบรับรองอิเล็กทรอนิกส์",
                    key: "/certificates",
                    url: "/certificates",
                    iconProps: {
                      iconName: "Certificate",
                      styles: {
                        root: {
                          fontSize: "26px",
                          color: "rgb(253, 253, 253)",
                        },
                      },
                    },
                    active: true,
                    onRender: (item) => {
                      return (
                        <SidebarButton
                          key={item.key}
                          url={item.url}
                          text={t("Certificates")}
                          iconProps={item.iconProps}
                          role="menuitem"
                          theme={getTheme()}
                          checked={certClick}
                          onClick={() => {
                            setEsignClick(false);
                            setDsignClick(false);
                            setCertClick(true);
                            setContactClick(false);
                            history.push(item.url);
                          }}
                          style={{ paddingLeft: "3%" }}
                          styles={{
                            root: {
                              backgroundColor: "#3A3A3A",
                              height: "100px",
                            },
                            rootHovered: {
                              backgroundColor: "#074f97",
                            },
                            rootPressed: {
                              backgroundColor: "#05386B",
                            },
                            rootFocused: {
                              backgroundColor: "#05386B",
                            },
                            rootChecked: {
                              backgroundColor: "#05386B",
                            },
                            rootCheckedHovered: {
                              backgroundColor: "#05386B",
                            },
                            rootCheckedPressed: {
                              backgroundColor: "#05386B",
                            },
                            label: {
                              fontSize: "20px",
                              color: "rgb(253, 253, 253)",
                              margin: "0px",
                              padding: "0px 0px 0px 10px"
                            },
                          }}
                        />
                      );
                    },
                  }
                ]
              : []
          ).concat(
            [
              {
                key: "contact",
                iconProps: {
                  iconName: "Telemarketer",
                  styles: {
                    root: {
                      fontSize: "26px",
                      color: "rgb(253, 253, 253)",
                      marginLeft: "12.5px",
                      marginRight: "0px"
                    },
                  },
                },
                onRender: (item) => {
                  return (
                    <SidebarButton
                      key="contact"
                      url={"/contact-us"}
                      text={t("Contact Us")}
                      iconProps={item.iconProps}
                      role="menuitem"
                      theme={getTheme()}
                      checked={contactClick}
                      onClick={() => {
                        setEsignClick(false);
                        setDsignClick(false);
                        setCertClick(false);
                        setContactClick(true);
                        history.push("/contact-us");
                      }}
                      styles={{
                        root: {
                          backgroundColor: "#2E302D",
                          height: "100px",
                        },
                        rootHovered: {
                          backgroundColor: "#074f97",
                        },
                        rootPressed: {
                          backgroundColor: "#05386B",
                        },
                        rootFocused: {
                          backgroundColor: "#05386B",
                        },
                        rootChecked: {
                          backgroundColor: "#05386B",
                        },
                        rootCheckedHovered: {
                          backgroundColor: "#05386B",
                        },
                        rootCheckedPressed: {
                          backgroundColor: "#05386B",
                        },
                        label: {
                          fontSize: "22px",
                          color: "rgb(253, 253, 253)",
                          paddingLeft: "15px"
                        },
                      }}
                    />
                  );
                },
              }
            ]
          )}
      />
    </div>
  );
};

export default FluentSidebar;
